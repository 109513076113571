import TextField from "./TextField"
import DateField from "./DateField"
import FileField from "./FileField"
// import SliderSelectField from "./SliderSelectField"
import { RadioSelectField } from "./RadioSelectField"
import { CheckboxSelectField } from "./CheckboxSelectField"
import CheckboxField, { SwitchField } from "./CheckboxField"
import SelectField, { MultipleSelectField } from "./SelectField"
import { LargeListSelectField, MinimisedLargeListSelectField } from "./LargeListSelectField"



const Field = {
    Text: TextField,
    Date: DateField,
    
    Checkbox: CheckboxField,
    Switch: SwitchField,
    
    File: FileField,
    Select: SelectField,
    Radio: RadioSelectField,
    // SliderSelect: SliderSelectField,
    CheckboxSelect: CheckboxSelectField,
    MultipleSelect: MultipleSelectField,
    LargeListSelect: LargeListSelectField,
    MinimisedLargeListSelect: MinimisedLargeListSelectField,
}


export default Field