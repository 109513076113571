import React from "react"
import Ajax from "../../../Ajax"
import QuestionnaireTable from "../../views/questionnaire_mgmt/QuestionnaireTable"
import { Alert } from "../../.."
import QuestionnaireDetails from "../../views/questionnaire_mgmt/QuestionnaireDetails"
import { Button } from "@mui/material"
import QuestionnaireFilterForm from "./QuestionnaireFilterForm"

export default function QuestionnaireSelectField ({
    value,
    highlightedOptions = [],
    showRemoveButton = false,
    returnQuestionnaire = false,
    onChange = (value) => {}
}) {

    const [questionnaire, setQuestionnaire] = React.useState(null)
    const [loading, setLoading] = React.useState(value && !questionnaire)

    React.useEffect(() => {
        if (value && !questionnaire) {
            getQuestionnaire(value)
        } else if (!value) {
            setQuestionnaire(null)
        }
    }, [value])

    const getQuestionnaire = async (uid) => {
        setLoading(true)
        
        await Ajax.Questionnaire.Get(uid).then((response) => {
            setQuestionnaire(response.data)
            onChange(returnQuestionnaire ? questionnaire : uid)
        }).catch(() => {
            Alert({message: "Failed to get questionnaire"})
        })

        setLoading(false)
    }

    if (questionnaire) {
        return (
            <div className={loading ? "disabled" : ""}>
                <QuestionnaireDetails 
                    height="260px"
                    questionnaire={questionnaire} />
                
                {showRemoveButton ? (
                    <Button 
                        className="btn btn-xs" 
                        variant="contained" 
                        color="error" 
                        style={{marginTop: "10px"}}
                        onClick={() => {
                            setQuestionnaire(null)
                            onChange(null)
                        }}>
                        Remove selection
                    </Button>
                ) : null}
            </div>
        )
    }

    return (
        <div className={loading ? "disabled" : ""} style={{display: "grid", gap: "10px"}}>
            <QuestionnaireFilterForm />
            <QuestionnaireTable 
                size="sm" 
                width="400px" 
                height="300px" 
                highlightedOptions={highlightedOptions}
                onRowClick={(uid) => {
                    console.log(uid)
                    getQuestionnaire(uid)
                }} />
        </div>
    )
}