import { observer } from "mobx-react-lite";
import { AlertModal } from "../../../../..";
import { store } from "../../../../../stores/Store";
import { Button, CircularProgress } from "@mui/material";
import CreateAutomationForm from "../../../../form/automation/CreateAutomationForm";


interface AutomationOptionsProps {
    automationOptions: any[];
}


export default observer(function AutomationOptions ({automationOptions}: AutomationOptionsProps) {

    var { enquiry } = store.EnquiryStore

    if (enquiry.status !== "DEFINITIVE") return <></>

    var automationInProgress = !!store.AutomationStore.runningAutomation

    automationOptions.sort((a, b) => a.label.localeCompare(b.label))
    var cos_forms = automationOptions.filter(option => option.id.includes("COS"))                                           
    var subjectForms = automationOptions.filter(option => !option.id.includes("CHILD") && !option.id.includes("PARTNER") && !option.id.includes("COS"))
    var dependantForms = automationOptions.filter(option => option.id.includes("CHILD") || option.id.includes("PARTNER"))

    // Available form
    return (
        <div className="form">
            {automationInProgress ? (
                <div style={{display: "flex", gap: "10px", justifyContent: "normal", alignItems: "center"}}>
                    <CircularProgress style={{width: "10px", height: "10px", margin: "0"}} />
                    <small>Automation in progress</small>
                </div>
            ) : (
                <p>We have found {automationOptions?.length > 1 ? `${automationOptions.length} online forms which are` : "an online form which is"} relevant to this enquiry.</p>
            )}

            <div>
                <h4>Main Applicant</h4>
                {cos_forms.map((option, i) => <AutomationOption key={i} automationOption={option} />)}
                {subjectForms.map((option, i) => <AutomationOption key={i} automationOption={option} />)}
            </div>

            {dependantForms?.length ? (
                <div>
                    <h4>Dependants</h4>
                    {dependantForms.map((option, i) => <AutomationOption key={i} automationOption={option} />)}
                </div>
            ) : null}
        </div>
    )
})


function AutomationOption ({automationOption}) {

    var automationInProgress = !!store.AutomationStore.runningAutomation

    return (
        <div style={{width: "600px", display: "grid", gridTemplateColumns: "auto 100px 110px", margin: "10px 10px 10px 20px"}}>
            <div style={{display: "list-item"}}>
                {automationOption.label}
            </div>
            
            <div>
                <a href={automationOption.url} target="_blank" rel="noreferrer" className="link">Go to form</a>
            </div>

            <div>
                <Button
                    color="success"
                    variant="contained"
                    className="btn btn-sm"
                    disabled={automationInProgress}
                    onClick={() => {
                        store.AlertStore.Close()

                        AlertModal({
                            title: "Create Automation", 
                            width: "700px", 
                            body: <CreateAutomationForm automationOption={automationOption} />
                        })
                    }}>
                    Automate
                </Button>
            </div>
        </div>
    )
}