import React from "react"
import TruncText from "../../../../../misc/TruncText"
import { Button } from "@mui/material"


export default function AutomationResumeDetails ({automator}) {

    // var outputData = automationNode.serialisedInstance
    // outputData = JSON.parse(outputData)

    const [revealPassword, setRevealPassword] = React.useState(false)

    if (automator.navigation_style === "cos_sms") {
        return (
            <div style={{marginLeft: "12px"}}>
                <small style={{marginBottom: "8px"}}>Sign into the SMS to resume this CoS application.</small>
                <Button className="btn btn-sm" variant="contained" color="success">
                    <a href="https://www.points.homeoffice.gov.uk/gui-sms-jsf/home/SMS-003-Home.faces" target="_default" rel="noreferrer">
                        Sign into SMS
                    </a>    
                </Button>  
            </div>
        )
    }

    if (automator.profile?.id_check_document_type) {
        return (
            <div style={{marginLeft: "12px"}}>
                <div className="font-xs">Date of birth:</div>
                <div style={{margin: "0 0 5px 5px"}}>{automator.profile?.id_check_document_date_of_birth}</div>
                
                <div className="font-xs">Document type:</div>
                <div style={{margin: "0 0 5px 5px"}}>
                    <TruncText text={automator.profile?.id_check_document_type} />
                </div>
            
                <div className="font-xs">Document number:</div>
                <div style={{margin: "0 0 5px 5px"}}>{automator.profile?.id_check_document_number}</div>

                <Button
                    className="btn btn-sm"
                    variant="contained"
                    color="success"
                    onClick={() => window.open("https://apply-to-visit-or-stay-in-the-uk.homeoffice.gov.uk/dashboard")}>
                    Resume
                </Button>
            </div>
        )
    }
    
    if (automator.resume_url) {
        return (
            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
                <div style={{marginLeft: "12px"}}>
                    <div className="font-xs">Email:</div>
                    <div style={{margin: "0 0 5px 5px", fontSize: "12px"}}>
                        <TruncText text={automator.profile?.advisor_email} />
                    </div>

                    <div className="font-xs">Password:</div>
                    <div style={{margin: "0 0 5px 5px"}}>
                        {revealPassword ? (
                            <div style={{display: "flex", gap: "5px"}}>
                                <span className="code font-grey font-sm">{automator.profile?.password}</span>
                                <Button 
                                    className="btn btn-xs" 
                                    onClick={() => navigator.clipboard.writeText(automator.profile?.password)}>
                                    Copy
                                </Button>
                            </div>
                        ) : (
                            <Button 
                                className="btn btn-sm" 
                                variant="contained" 
                                color="info" 
                                onClick={() => setRevealPassword(!revealPassword)}>
                                {revealPassword ? "Hide" : "Show"} password
                            </Button>
                        )}
                    </div>

                    <div className="font-xs">Resume on Home Office website:</div>
                    <div style={{margin: "0 0 5px 5px"}}>
                        <Button className="btn btn-sm" variant="contained" color="success">
                            <a 
                                href={automator.resume_url} 
                                target="_default" 
                                rel="noreferrer">
                                Resume
                            </a>    
                        </Button>  
                    </div>
                </div>
            </div>
        )
    }

    return <small style={{margin: "0 0 0 10px"}}>No resume options</small>
}