import { store } from '../../../stores/Store';
import ErrorBoundary from '../../../misc/ErrorBoundary';
import React from 'react';
import PanelBody from '../../layout/PanelBody';
import PanelHeader from '../../layout/PanelHeader';
import { Button, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Icons } from '../../../misc/Icons';
import QuestionnaireTable from './QuestionnaireTable';
import { AlertModal } from '../../..';
import CreateQuestionnaireForm from './CreateQuestionnaireForm';
import QuestionnaireTemplateCreateForm from '../../form/QuestionnaireTemplateCreateForm';
import ViewQuestionnaire from './ViewQuestionnaire';


export default observer(function QuestionnaireTool () {        

    const { getQuestionnaires, searchString, setSearchString  } = store.QuestionnaireStore

    return (
        <ErrorBoundary>
            <div className="panel panel-dark">
                <PanelHeader>
                    <div className="header">
                        <h2>Questionnaires</h2>
                        
                        <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                            <div className="form-search form-search-dark">
                                <input 
                                    autoFocus 
                                    value={searchString}
                                    placeholder="Search"
                                    spellCheck="false"
                                    onChange={(e) => {
                                        setSearchString(e.target.value)
                                    }} />
                            </div>
                            <Button
                                color="info"
                                className="btn btn-sm"
                                onClick={() => getQuestionnaires()}>
                                <Icons.Refresh /> Refresh
                            </Button>
                        </div>
                        
                        <Button
                            className="btn btn-sm"
                            variant="contained"
                            onClick={() => AlertModal({body: <CreateQuestionnaireForm />, width: "550px"})}>
                            Create Questionnaire
                        </Button>

                        {store.AppStore.checkJwtRole("sysuser") ? (
                            <Button
                                className="btn btn-sm"
                                variant="contained"
                                onClick={() => AlertModal({body: <QuestionnaireTemplateCreateForm />, width: "550px"})}>
                                Create Template
                            </Button>
                        ) : null}
                    </div>
                    
                    <IconButton style={{marginLeft: "auto"}} size="small" onClick={() => store.ToolStore.setTool(null)}><Icons.X /></IconButton>
                </PanelHeader>
                
                <PanelBody>
                    <QuestionnaireTable onRowClick={(uid) => AlertModal({width: "700px", body: <ViewQuestionnaire uid={uid} />})} />
                </PanelBody>
            </div>
        </ErrorBoundary>
    )
})