import { observer } from "mobx-react-lite";
import { store } from "../../../../../stores/Store";
import { Button, CircularProgress } from "@mui/material";
import { AlertModal } from "../../../../..";
import AutomationOptions from "./AutomationOptions";

export default observer(function AutomationCta() {
    
    const { enquiry, getQualifyingProvision } = store.EnquiryStore
    const { getQualifyingOnlineForms } = store.AutomationStore
    
    if (enquiry.status !== "DEFINITIVE") {
        return (
            <div className="section-subheader">
                <small>You must complete the enquiry before creating automations.</small>
            </div>
        )
    }
    
    var provision = getQualifyingProvision()
    var automationOptions = getQualifyingOnlineForms(provision?.rule_id)
    var runningAutomation = store.AutomationStore.runningAutomation
    
    if (!automationOptions?.length) {
        return (
            <div className="section-subheader">
                <small>You don't have any automation options.</small>
            </div>
        )
    }
    
    return (
        <>
            <div className="section-subheader">
                {runningAutomation ? (
                    <div style={{ display: "flex", gap: "10px", justifyContent: "normal", alignItems: "center" }}>
                        <CircularProgress style={{ width: "10px", height: "10px", margin: "0" }} />
                        {enquiry.uid === runningAutomation.enquiryUid ? (
                            <small>Automation in progress</small>
                        ) : (
                            <small onClick={() => store.NodeStore.navigateNode(runningAutomation.enquiryUid)} className="link">Automation in progress under {runningAutomation.enquiryUid}</small>
                        )}
                    </div>
                ) : (
                    <small>We have found {automationOptions?.length > 1 ? `${automationOptions.length} online forms which are` : "an online form which is"} relevant to this enquiry.</small>
                )}
            </div>
            <Button
                color="success"
                className="btn btn-md"
                variant="contained"
                style={{ margin: "8px" }}
                disabled={!!runningAutomation}
                onClick={async () => {
                    AlertModal({
                        title: "Create Automation", width: "700px", body: (
                            <AutomationOptions automationOptions={automationOptions} />
                        )
                    })
                }}>
                Create Automation
            </Button>
        </>
    )
})