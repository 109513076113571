import * as Yup from 'yup'
import { useFormik } from "formik"
import Ajax from '../../../Ajax'
import { store } from '../../../stores/Store'
import Field from '../fields/Field'
import { Button, CircularProgress } from '@mui/material'
import { Icons } from '../../../misc/Icons'

export default function MfaForm ({webSessionId, mfaEmail, mfaMessage, backToLogin}) {

    const formik = useFormik({

        initialValues: {
            mfaCode: "",
            webSessionId: webSessionId
        },

        validationSchema: Yup.object({
            mfaCode: Yup.string().required("Please provide the MFA code"),
        }),

        onSubmit: async (values, actions) => {

            values.mfaCode = values.mfaCode.trim()

            return await Ajax.Session.Mfa(values).then(async (response) => {

                await store.AppStore.init(response.data.token)
                store.AppStore.navigate("/app/main")
            
            }).catch((response) => {
                
                console.log(response)
                var errors: any = {}
                
                if (!response) {
                    errors.mfaCode = "Check your internet connection"
                } else if (response.data?.errors) {
                    Object.keys(response?.data?.errors).forEach(key => errors[key] = String(response.data?.errors[key]))
                } else if (response.data?.detail) {
                    errors.mfaCode = response.data?.detail
                } else {
                    errors.mfaCode = "Something went wrong"  
                }

                actions.setErrors(errors)
            })
        }
    })

    return (
        <form autoComplete="on" className="form form-login" onSubmit={formik.handleSubmit}>

            <h1 style={{fontSize: "30px"}}>MFA</h1>
            
            <small>Your account is configured to use Multifactor Authentication (MFA).</small>

            {mfaMessage ? (
                <small>{mfaMessage}</small>
            ) : (
                <small>A multi factor authentication (MFA) code has been sent to {mfaEmail}.</small>
            )}

            <div className="form-fields">
                <Field.Text
                    formik
                    name="mfaCode"
                    label="Enter your MFA code here..."
                    autoFocus={true}
                    autoComplete={true}
                    warning={formik.submitCount && formik.errors.mfaCode ? formik.errors.mfaCode : ""}
                    value={formik.values.mfaCode}
                    onChange={formik.handleChange} />
            </div>

            <div className="form-footer">
                <Button className="btn-md" disabled={formik.isSubmitting} type="submit" color="primary" variant="contained">
                    {!formik.isSubmitting ? "Sign in" : <CircularProgress />}
                </Button>

                <Button className="btn btn-sm" color="info" onClick={() => backToLogin()}>Back to Login</Button>
            </div>
        </form>
    )
}