import React from "react"
import Ajax from "../../../Ajax"
import { store } from "../../../stores/Store"
import MicrosoftOAuthForm from "./MicrosoftOAuthForm"
import LoginForm from "./LoginForm"
import MfaForm from "./MfaForm"

export default function AuthenticationForms () {

    // Get connection options
    const [connectionOptions, setConnectionOptions] = React.useState<string[]>(null)
    const [mfaEmail, setMfaEmail] = React.useState(null)
    const [webSessionId, setWebSessionId] = React.useState(null)
    const [mfaMessage, setMfaMessage] = React.useState(null)

    React.useEffect(() => {
        
        var subdomain = window.location.hostname.split(".")[0]
        if (subdomain.includes("localhost") || subdomain.includes("staging") || subdomain.includes("dev")) {
            
            Ajax.Session.ConnectionOptions().then((response) => {
                setConnectionOptions(response.data)
            }).catch(() => {
                console.log("There was an error when getting connection options.")
            })
        }

    }, [])

    // Get OAuth provider
    var oauthProvider = store.AppStore.getCookie("AORA-OAuth-provider")

    if (oauthProvider === "microsoft") {
        return <MicrosoftOAuthForm />
    }

    if (mfaEmail && webSessionId) {
        return (
            <MfaForm 
                mfaMessage={mfaMessage}
                mfaEmail={mfaEmail} 
                webSessionId={webSessionId} 
                backToLogin={() => {
                    setMfaEmail(null)
                    setWebSessionId(null)
                    setMfaMessage(null)
                }} />
        )
    }

    return (
        <LoginForm 
            connectionOptions={connectionOptions} 
            initiateMfa={(_mfaEmail, _webSessionId, _message) => {
                setMfaEmail(_mfaEmail)
                setMfaMessage(_message)
                setWebSessionId(_webSessionId)
            }} />
    )
}