// Labels for attribute keys can be set here if the key lacks readability.
// It also defines the order that keys should be displayed.
export const attributeKeys = {
    "subject_title": null,
    "subject_first_name": null,
    "subject_family_name": null,
    "subject_has_other_names": null,
    "subject_passport_number": null,
    "subject_passport_country": null,
    "subject_passport_sex": null,
    "subject_passport_issue_date": null,
    "subject_passport_issue_date_year": "Applicant passport issue date, year",
    "subject_passport_issue_date_month": "Applicant passport issue date, month",
    "subject_passport_issue_date_day": "Applicant passport issue date, day",
    "subject_passport_expiry_date": null,
    "subject_passport_expiry_date_year": "Applicant passport expiry date, year",
    "subject_passport_expiry_date_month": "Applicant passport expiry date, month",
    "subject_passport_expiry_date_day": "Applicant passport expiry date, day",
    "subject_passport_biometric": null,
    "subject_passport_can_be_provided": null,
    "subject_has_valid_id_card": null,
    "subject_id_card_number": null,
    "subject_id_card_country": null,
    "subject_id_card_issue_date": null,
    "subject_id_card_issue_date_year": "Applicant id card issue date, year",
    "subject_id_card_issue_date_month": "Applicant id card issue date, month",
    "subject_id_card_issue_date_day": "Applicant id card issue date, day",
    "subject_id_card_expiry_date": null,
    "subject_id_card_expiry_date_year": "Applicant id card expiry date, year",
    "subject_id_card_expiry_date_month": "Applicant id card expiry date, month",
    "subject_id_card_expiry_date_day": "Applicant id card expiry date, day",
    "subject_country_of_birth": null,
    "subject_born_in_UK": "Applicant was born in the UK",
    "subject_place_of_birth": null,
    "subject_date_of_birth": null,
    "subject_gender": null,
    "subject_email": null,
    "subject_has_phone_number": null,
    "subject_phone": null,
    "subject_country_of_nationality": null,
    "subject_has_other_nationalities": null,
    "subject_applying_from": null,
    "subject_applying_from_location": null,
    "subject_time_in_uk_months": "Applicant time in the UK months",
	"subject_time_in_uk_remainder_months": "Applicant time in the UK remainder months",
	"subject_time_in_uk_years": "Applicant time in the UK years",
    "subject_residence_country_start_date": null,
    "subject_residence_country_start_date_year": "Applicant residence country start date, year",
    "subject_residence_country_start_date_month": "Applicant residence country start date, month",
    "subject_residence_country_start_date_day": "Applicant residence country start date, day",
    "subject_address_line_1": null,
    "subject_city": null,
    "subject_province": null,
    "subject_post_code_foreign": null,
    "subject_post_code": null,
    "subject_country_of_residence": null,
    "subject_address_since": null,
    "subject_address_is_correspondence_address": null,
    "subject_correspondence_address_line_1": null,
    "subject_correspondence_city": null,
    "subject_correspondence_post_code_foreign": null,
    "subject_correspondence_post_code": null,
    "subject_correspondence_country": null,
    "subject_relation_to_property": null,
    "subject_relation_to_property_explanation": null,
    "subject_landlord_name": null,
    "subject_landlord_phone": null,
    "subject_landlord_address": null,
    "subject_landlord_city": null,
    "subject_landlord_country_of_residence": null,
    "subject_has_previous_addresses_in_past_2_years": null,
    "subject_current_visa_type": null,
    "subject_current_visa_sw_type": null,
    "subject_current_visa_start_date": null,
    "subject_current_visa_start_date_year": "Applicant current visa start date, year",
    "subject_current_visa_start_date_month": "Applicant current visa start date, month",
    "subject_current_visa_start_date_day": "Applicant current visa start date, day",
    "subject_current_visa_end_date": null,
    "subject_current_visa_end_date_year": "Applicant current visa end date, year",
    "subject_current_visa_end_date_month": "Applicant current visa end date, month",
    "subject_current_visa_end_date_day": "Applicant current visa end date, day",
    "subject_current_visa_2010_code": null,
    "subject_current_visa_2020_code": null,
    "subject_current_visa_same_sponsor": null,
    "subject_total_months_in_uk": "Applicant total months in uk",
    "subject_has_BRP_card": null,
    "subject_BRP_number": null,
    "subject_has_other_home_office_applications": null,
    "subject_has_received_sponsorship_for_uk_education": "Applicant has received sponsorship for the UK education",
    "subject_prefered_language_is_english": "Applicant prefered language is English",
    "subject_prefered_language": null,
    "subject_method_of_proving_english_language_ability": "Applicant method of proving English language ability",
    "subject_passed_english_language_test_with_B1_or_higher": "Applicant passed English language test with B1 or higher",
    "subject_english_language_awarding_body": "Applicant English language awarding body",
    "subject_english_language_award_date": "Applicant English language award date",
    "subject_english_language_award_date_year": "Applicant English language award date, year",
    "subject_english_language_award_date_month": "Applicant English language award date, month",
    "subject_english_language_award_date_day": "Applicant English language award date, day",
    "subject_requires_proof_of_english_language": "Applicant requires proof of English language",
    "subject_english_language_award_reference_number": "Applicant English language award reference number",
    "subject_passed_english_requirement_if_health_worker": "Applicant passed English requirement if health worker",
    "subject_provided_evidence_of_english_language_assessment_previously": null,
    "subject_has_english_qualification_from_uk_school": "Applicant has English qualification from the UK school",
    "subject_degree_that_was_taught_in_english": "Applicant degree that was taught in English",
    "subject_country_that_degree_was_taught_in": null,
    "subject_degree_language": null,
    "subject_degree_proof_type": null,
    "subject_degree_evidence_reason": null,
    "subject_degree_ecctis_confirmation": null,
    "subject_degree_ecctis_number": null,
    "subject_has_english_qualification_from_uk_school_date": "Applicant has english qualification from the UK school date",
    "subject_has_english_qualification_from_uk_school_date_year": "Applicant has english qualification from the UK school date, year",
    "subject_has_english_qualification_from_uk_school_date_month": "Applicant has english qualification from the UK school date, month",
    "subject_has_english_qualification_from_uk_school_date_day": "Applicant has english qualification from the UK school date, day",
    "subject_majority_english_speaking_proof_type": "Applicant majority english speaking proof type",
    "subject_majority_english_speaking_proof_country": "Applicant majority english speaking proof country",
    "subject_majority_english_speaking_proof_number": "Applicant majority english speaking proof number",
    "subject_majority_english_speaking_proof_number": "Applicant majority english speaking proof number",
    "subject_has_relevant_phd": "Applicant has a relevant PhD",
    "subject_phd_subject": "Applicant PhD subject",
    "subject_phd_stem_subject": "Applicant PhD stem subject",
    "subject_ecctis_certified_phd": "Applicant ecctis certified PhD",
    "subject_ecctis_phd_number": "Applicant ECCTIS PhD number",
    "applicant_has_other_degrees": null,
    "subject_relationship_status": null,
    "subject_partner_title": null,
    "subject_partner_first_name": null,
    "subject_partner_family_name": null,
    "subject_partner_date_of_birth": null,
    "subject_partner_country_of_nationality": null,
    "subject_partner_phone": null,
    "subject_partner_has_other_names": null,
    "subject_partner_has_valid_passport": null,
    "subject_partner_passport_number": null,
    "subject_partner_passport_issuing_authority": null,
    "subject_partner_passport_issue_date": null,
    "subject_partner_passport_issue_date_year": "Applicant partner passport issue date, year",
    "subject_partner_passport_issue_date_month": "Applicant partner passport issue date, month",
    "subject_partner_passport_issue_date_day": "Applicant partner passport issue date, day",
    "subject_partner_passport_expiry_date": null,
    "subject_partner_passport_expiry_date_year": "Applicant partner passport expiry date, year",
    "subject_partner_passport_expiry_date_month": "Applicant partner passport expiry date, month",
    "subject_partner_passport_expiry_date_day": "Applicant partner passport expiry date, day",
    "subject_partner_passport_can_be_provided": null,
    "subject_partner_cohabiting": null,
    "subject_partner_cohabiting_in_uk_start_date": null,
    "subject_partner_cohabiting_in_uk_start_date_year": "Applicant partner cohabiting in the UK start date, year",
    "subject_partner_cohabiting_in_uk_start_date_month": "Applicant partner cohabiting in the UK start date, month",
    "subject_partner_cohabiting_in_uk_start_date_day": "Applicant partner cohabiting in the UK start date, day",
    "subject_partner_address_is_in_UK": null,
    "subject_partner_address_line_1": null,
    "subject_partner_city": null,
    "subject_partner_region": null,
    "subject_partner_post_code": null,
    "subject_partner_post_code_foreign": null,
    "subject_partner_address_country": null,
    "subject_partner_cohabiting": null,
    "subject_partner_cohabiting_outside_uk_start_date": null,
    "subject_partner_cohabiting_outside_uk_start_date_year": "Applicant partner cohabiting outside the UK start date, year",
    "subject_partner_cohabiting_outside_uk_start_date_month": "Applicant partner cohabiting outside the UK start date, month",
    "subject_partner_cohabiting_outside_uk_start_date_day": "Applicant partner cohabiting outside the UK start date, day",
    "subject_partner_address_is_in_UK": null,
    "subject_partner_address_line_1": null,
    "subject_partner_city": null,
    "subject_partner_region": null,
    "subject_partner_post_code": null,
    "subject_partner_post_code_foreign": null,
    "subject_partner_address_country": null,
    "subject_first_parent_details_known": null,
    "subject_first_parent_relationship": null,
    "subject_first_parent_title": null,
    "subject_first_parent_first_name": null,
    "subject_first_parent_family_name": null,
    "subject_first_parent_date_of_birth": null,
    "subject_first_parent_country_of_nationality": null,
    "subject_first_parent_always_had_the_same_nationality": null,
    "subject_first_parent_country_of_nationality_at_birth": null,
    "subject_second_parent_details_known": null,
    "subject_second_parent_relationship": null,
    "subject_second_parent_title": null,
    "subject_second_parent_first_name": null,
    "subject_second_parent_family_name": null,
    "subject_second_parent_date_of_birth": null,
    "subject_second_parent_country_of_nationality": null,
    "subject_second_parent_always_had_the_same_nationality": null,
    "subject_second_parent_country_of_nationality_at_birth": null,
    "subject_has_family_in_uk": "Applicant has family in the UK",
    "subject_knows_uk_travel_date": "Applicant knows UK travel date",
    "subject_knows_uk_travel_date_year": "Applicant knows UK travel date, year",
    "subject_knows_uk_travel_date_month": "Applicant knows UK travel date, month",
    "subject_knows_uk_travel_date_day": "Applicant knows UK travel date, day",
    "subject_uk_travel_date": "Applicant UK travel date",
    "subject_uk_travel_date_year": "Applicant UK travel date, year",
    "subject_uk_travel_date_month": "Applicant UK travel date, month",
    "subject_uk_travel_date_day": "Applicant UK travel date, day",
    "subject_knows_where_they_will_stay_in_uk": null,
    "subject_future_uk_address": "Applicant future UK address",
    "subject_future_uk_post_code": "Applicant future UK post code",
    "subject_future_uk_city": "Applicant future UK city",
    "subject_leave_to_remain_applied_in_past_10_years": null,
    "subject_has_had_any_immigration_rejections": null,
    "subject_had_medical_treatment_in_the_uk": null,
    "subject_has_national_insurance_number": null,
    "subject_national_insurance_number": null,
    "subject_has_driving_licence": null,
    "subject_driving_licence_number": null,
    "criminal_record_certificate_required": null,
    "tb_certificate_provided": null,
    "tb_certificate_date": null,
    "tb_certificate_date_year": "Tuberculosis certificate date, year",
    "tb_certificate_date_month": "Tuberculosis certificate date, month",
    "tb_certificate_date_day": "Tuberculosis certificate date, day",
    "subject_has_received_public_funds_in_uk": "Applicant has received public funds in uk",
    "subject_has_ever_breached_uk_immigration_law": "Applicant has ever breached UK immigration law",
    "subject_has_convictions_or_penalties": null,
    "subject_involved_in_war_crimes": null,
    "subject_supported_terrorist_activities": null,
    "subject_supported_terrorist_organisations": null,
    "subject_expressed_terrorist_views": null,
    "subject_has_been_a_involved_with_an_extremist_organisation": null,
    "subject_has_expressed_extremist_views": null,
    "subject_has_been_employed_by_hostile_government": null,
    "subject_has_engaged_in_activities_considered_not_to_be_of_good_character": null,
    "subject_has_anything_else_to_come_clean_about": null,
    "subject_has_anything_else_to_come_clean_about_details": null,
    "subject_has_not_worked_in_armed_forces_or_government_or_intelligence_or_security_or_media_or_judiciary": null,
    "subject_has_served_in_the_armed_forces": null,
    "subject_has_served_in_the_armed_forces_details": null,
    "subject_has_served_in_the_armed_forces_compulsory": null,
    "subject_has_served_in_the_armed_forces_compulsory_details": null,
    "subject_has_worked_for_government": null,
    "subject_has_worked_for_government_details": null,
    "subject_has_worked_in_intelligence": null,
    "subject_has_worked_in_intelligence_details": null,
    "subject_has_worked_in_security": null,
    "subject_has_worked_in_security_details": null,
    "subject_has_worked_in_media": null,
    "subject_has_worked_in_media_details": null,
    "subject_has_worked_in_judiciary": null,
    "subject_has_worked_in_judiciary_details": null,
    "subject_to_be_paid_on_paye": "Applicant to be paid on paye",
    "subject_sponsor_certifying_mainenance": "The sponsor will certify financial maintenance for the applicant",
    "subject_supporter_sponsor_certifying_mainenance": "The sponsor will certify financial maintenance for the worker",

    "security_question_set_1": null,
    "security_question_first_school": null,
    "security_question_wedding_city": null,
    "security_question_first_job": null,
    "security_question_favourite_teacher": null,
    "security_question_parents_met": null,
    "security_question_set_2": null,
    "security_question_location_born": null,
    "security_question_first_street_name": null,
    "security_question_dream_job": null,
    "security_question_partner_name": null,
    "security_question_first_pet": null,
    "security_question_set_3": null,
    "security_question_favourite_film": null,
    "security_question_partner_mother": null,
    "security_question_father_birthplace": null,
    "security_question_first_car": null,
    "security_question_eldest_sibling": null,
    "confirm_information": null,
    "confirm_information_explanation": null,






	"advisor_organisation": null,
	"advisor_family_name": null,
	"advisor_first_name": null,
	"advisor_email": null,
	"advisor_phone": null,
    "advisor_address_line_1": null,
	"advisor_post_code": null,
	"advisor_city": null,
	"advisor_country": null,
	"advisor_is_based_in_uk": "Advisor is based in the UK",
	"advisor_regulation_number": null,
	"advisor_regulator": null,
	"cos_duration_3_years": "CoS duration 3 years",
	"cos_end_date": "CoS end date",
	"cos_end_date_day": "CoS end date day",
	"cos_end_date_month": "CoS end date month",
	"cos_end_date_year": "CoS end date year",
	"cos_months": "CoS months",
	"job_title": null,
	"job_description": null,
	"job_code": null,
	"job_code_known": null,
	"job_code_match": null,
	"job_code_sms": "Job code for SMS",
	"job_salary": null,
	"job_salary_hpw": null,
	"job_salary_pay_basis": null,
	"job_salary_per_pay_basis": null,
	"job_salary_per_year": null,
	"job_standard_pay": null,
	"job_hours_per_week": null,
	"job_start_date": null,
	"job_start_date_day": "Job start date, day",
	"job_start_date_month": "Job start date, month",
	"job_start_date_year": "Job start date, year",
	"job_end_date": null,
	"job_end_date_day": "Job end date, day",
	"job_end_date_month": "Job end date, month",
	"job_end_date_year": "Job end date, year",
	"job_address_city": null,
	"job_address_line_1": null,
	"job_address_postcode": null,
	"job_address_region": null,
	"job_location": null,
	"job_country": null,
	"job_has_other_addresses": null,
	"job_atas_approval_required": "Job ATAS approval required",
	"job_genuine": "Job is genuine",
	"job_involves_phd_level_research": "Job involves PhD level research",
	"job_eligible_for_phd_points": "Job eligible for PhD points",
	"job_is_in_health_care": null,
	"job_nurse_or_midwife": null,
	"job_on_salary_list": null,
	"job_requires_cqc_registration": "Job requires CQC registration",
	"job_requires_overseas_criminal_record_check": null,
	"job_third_party_work": null,
	"job_third_party_work_included": null,
}