import React from "react";
import Ajax from "../../Ajax";
import { useState } from "react"
import Help from "../../misc/Help";
import Field from "./fields/Field";
import { Icons } from "../../misc/Icons";
import { DisplayDictionary } from "../..";
import { observer } from "mobx-react-lite";
import { store } from "../../stores/Store";
import { Button, IconButton } from "@mui/material"
import { FormFieldOutput } from "../../models/Interfaces";
import { addDays, differenceInDays, isValid, subDays } from "date-fns";


interface AbsneceFormProps {
    absenceBlock: any;
}


export default observer(function AbsenceForm ({absenceBlock}: AbsneceFormProps) {

    // Convert date strings to Date obejcts
    const periodStart = new Date(absenceBlock.FromDate);
    const periodEnd = absenceBlock.ToDate.includes("9999") ? new Date() : new Date(absenceBlock.ToDate);

    absenceBlock.AbsenceItems.forEach(row => {
        row.ToDate = new Date(row.ToDate)
        row.FromDate = new Date(row.FromDate)
    })
    absenceBlock.IllegalPresenceItems.forEach(row => {
        row.ToDate = new Date(row.ToDate)
        row.FromDate = new Date(row.FromDate)
    })

    const [submitting, setSubmitting] = React.useState(false)
    const [warnings, setWarnings] = useState<string[]>([])
    const [absenceRows, setAbsenceRows] = useState(absenceBlock.AbsenceItems ? absenceBlock.AbsenceItems : [])
    const [illegalPresenceRows, setIllegalPresenceRows] = useState(absenceBlock.IllegalPresenceItems ? absenceBlock.IllegalPresenceItems : [])

    const addAbsence = () => {
        setAbsenceRows(
            [...absenceRows, {
                FromDate: null,
                ToDate: null,
                Purpose: "",
                CountriesVisited: [],
                Certainty: "CERTAIN",
            }]
        )
    }

    const addIllegalPresence = () => {
        setIllegalPresenceRows(
            [...illegalPresenceRows, {
                FromDate: null,
                ToDate: null,
                Certainty: "CERTAIN"
            }]
        )
    }

    const removeAbsence = (index: number) => {
        setAbsenceRows([...absenceRows.filter((row, r) => r !== index)])
    }

    const removeIllegalPresence = (index: number) => {
        setIllegalPresenceRows([...illegalPresenceRows.filter((row, r) => r !== index)])
    }

    const attemptSubmit = async () => {

        setSubmitting(true)
        var allWarnings = validate()

        var newAbsenceBlock = {
            Uid: absenceBlock.Uid,
            AbsenceItems: absenceRows,
            IllegalPresenceItems: illegalPresenceRows,
        }

        if (allWarnings.length === 0) {

            await Ajax.Message.RespondAbsence(newAbsenceBlock).then(() => {

                store.QuestionStore.informationRequest = null
                store.AlertStore.Close()

                if (store.AppStore.stateAction === "Editing") {
                    store.NodeStore.refreshLeafNode()
                }

            }).catch((response) => setWarnings([response.data?.detail]))

        }

        setSubmitting(false)
    }

    const handleAbsenceChange = (rowIndex: number, fieldOutput: FormFieldOutput) => {
        var table = [...absenceRows]
        table[rowIndex][fieldOutput.name] = fieldOutput.value
        setAbsenceRows(table)
    }

    const handleIllegalPresenceChange = (rowIndex: number, fieldOutput: FormFieldOutput) => {
        var table = [...illegalPresenceRows]
        table[rowIndex][fieldOutput.name] = fieldOutput.value
        console.log(illegalPresenceRows, fieldOutput)
        setIllegalPresenceRows(table)
    }


    const reorderRows = (rows: any[]) => {
        return [...rows].sort((a: any, b: any) => {
            return new Date(a.FromDate) > new Date(b.FromDate) ? 1 : -1
        })
    }

    const validateDates = (rows, tableName) => {

        var newWarnings = []

        rows.forEach((row, r) => {

            var rowTo = row.ToDate
            var rowFrom = row.FromDate
            console.log(tableName, row, rowTo, rowFrom)
            // Check for dates outside the date range
            if (rowTo < periodStart || rowFrom > periodEnd) {
                newWarnings.push(`${tableName} row ${r+1} is outside the time period`)
            }

            // Check if start date is after end date
            if (rowFrom > rowTo) {
                newWarnings.push(`${tableName} row ${r+1}: There must be at least one day between the "To date" and the "From date"`)
            }

            // Check for overlapping rows on absence table
            absenceRows.forEach((cRow, cr) => {
                if (row === cRow) { return }

                var cRowTo = cRow.ToDate
                var cRowFrom = cRow.FromDate

                if ((rowTo > cRowFrom && rowTo < cRowTo) || (rowFrom > cRowFrom && rowFrom < cRowTo)) {
                    newWarnings.push(`${tableName} row ${r+1} overlaps absence row ${cr+1}`)
                }
            })

            // Check for overlapping rows on absence table
            illegalPresenceRows.forEach((cRow, cr) => {
                if (row === cRow) { return }

                var cRowTo = cRow.ToDate
                var cRowFrom = cRow.FromDate

                if ((rowTo > cRowFrom && rowTo < cRowTo) || (rowFrom > cRowFrom && rowFrom < cRowTo)) {
                    newWarnings.push(`${tableName} row ${r+1} overlaps presence row ${cr+1}`)
                }
            })
        })

        return newWarnings
    }

    const validate = () => {

        setWarnings([])
        var completenessWarnings = []

        // Reorder rows
        setAbsenceRows(reorderRows(absenceRows))
        setIllegalPresenceRows(reorderRows(illegalPresenceRows))

        // Check for incompleteness
        var incompleteAbsenceRows = absenceRows.filter((row, r) => (!row.FromDate || !row.ToDate || !row.CountriesVisited.length || !row.Purpose))
        var incompletePresenceRows = illegalPresenceRows.filter((row, r) => (!row.FromDate || !row.ToDate))

        !!incompleteAbsenceRows.length && completenessWarnings.push("Absence rows are incomplete")
        !!incompletePresenceRows.length && completenessWarnings.push("Illegal presence rows are incomplete")

        var absenceValidation = validateDates(absenceRows, "absence")
        var presenceValidation = validateDates(illegalPresenceRows, "presence")

        console.log("Warnings", completenessWarnings, absenceValidation, presenceValidation)

        var allWarnings = [...completenessWarnings, ...absenceValidation, ...presenceValidation]
        setWarnings(allWarnings)
        return allWarnings
    }

    var shiftDays = true

    return (
        <div className="form form-absence">

            <h3>Absence from the United Kingdon</h3>

            <p>{absenceBlock.AbsenceInstruction}</p>

            <table className="absence-table">

                <thead>
                    <tr>
                        <td></td>
                        <td>{shiftDays ? "Departed" : "From"}</td>
                        <td>{shiftDays ? "Returned" : "To"}</td>
                        <td>Absent</td>
                        <td>Purpose</td>
                        <td>Country</td>
                        <td>Certainty</td>
                        <td></td>
                    </tr>
                </thead>

                <tbody>
                    {absenceRows.map((rowData: any, index: number) => {

                        var fromDate = rowData.FromDate
                        var toDate = rowData.ToDate
                        var daysCount = ""

                        if (!!fromDate && !!toDate) {
                            var daysCountInt = differenceInDays(toDate, fromDate) + 1
                            if (daysCountInt > -1 && daysCountInt < 10000) {
                                daysCount = daysCountInt.toString() + " days"
                            }
                        }

                        // Change dates from first/last-full-day to date-departed/returned
                        if (shiftDays && fromDate) {
                            fromDate = subDays(fromDate, 1)
                        }

                        if (shiftDays && toDate) {
                            toDate = addDays(toDate, 1)
                        }

                        return (
                            <tr key={index}>
                                <td style={{minWidth: 0, paddingRight: "0px"}}>{index + 1}</td>
                                <td style={{width: "80px"}}>

                                    <Field.Date
                                        formik
                                        name="FromDate"
                                        value={fromDate}
                                        warning={false}
                                        hideOpener={true}
                                        // minDate={periodStart}
                                        maxDate={toDate ? subDays(toDate, 2) : periodEnd}
                                        onChange={(e) => {
                                            // Change date back to first-full-day absent
                                            var value = new Date(e.target.value)

                                            if (shiftDays && value && isValid(value)) {
                                                value = addDays(value, 1)
                                            }

                                            handleAbsenceChange(index, {name: "FromDate", value: value})
                                        }}
                                    />

                                </td>
                                <td style={{width: "80px"}}>

                                    <Field.Date
                                        formik
                                        name="ToDate"
                                        value={toDate ? toDate : fromDate}
                                        warning={false}
                                        hideOpener={true}
                                        minDate={fromDate ? addDays(fromDate, 2) : periodStart}
                                        // maxDate={periodEnd}
                                        onChange={(e) => {
                                            // Change date back to last-full-day absent
                                            var value = new Date(e.target.value)

                                            if (shiftDays && value && isValid(value)) {
                                                value = subDays(value, 1)
                                            }

                                            handleAbsenceChange(index, {name: "ToDate", value: value})
                                        }}
                                    />

                                </td>
                                <td style={{width: "70px"}}>

                                    {daysCount ? (
                                        <Help content={daysCount}>
                                            <h3>{daysCount}</h3>
                                            <p>This is the number of <b>whole</b> days absent</p>
                                        </Help>
                                    ) : null}

                                </td>
                                <td style={{minWidth: "80px", maxWidth: "150px"}}>

                                    <Field.Text
                                        name="Purpose"
                                        value={rowData.Purpose}
                                        warning={false}
                                        onChange={(fieldOutput: FormFieldOutput) => handleAbsenceChange(index, fieldOutput)} />

                                </td>
                                <td style={{display: "flex", alignItems: "center", minWidth: "90px", maxWidth: "150px", paddingTop: "4px"}}>

                                    <Field.MinimisedLargeListSelect
                                        name="CountriesVisited"
                                        value={rowData.CountriesVisited}
                                        warning={false}
                                        maxValueCount={1}
                                        onChange={(fieldOutput: FormFieldOutput) => handleAbsenceChange(index, fieldOutput)}
                                        options={DisplayDictionary("countries", true)}  />

                                </td>
                                <td style={{width: "70px"}}>

                                    <Field.Select
                                        name="Certainty"
                                        value={rowData.Certainty}
                                        warning={false}
                                        onChange={(fieldOutput: FormFieldOutput) => handleAbsenceChange(index, fieldOutput)}
                                        options={[
                                            {value: "CERTAIN", label: "Certain"},
                                            {value: "DOUBTFUL", label: "Likely"}
                                        ]} />

                                </td>
                                <td style={{width: "15px"}}>
                                    <IconButton style={{padding: "0"}}>
                                        <Icons.X onClick={() => removeAbsence(index)} />
                                    </IconButton>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <Button variant="contained" className="btn btn-sm" onClick={() => addAbsence()}>Add absence</Button>

            <br/>

            <h3>Illegal presence in the United Kingdon</h3>

            <p>{absenceBlock.AbsenceInstruction}</p>
            <p style={{margin: 0}}><i>Note: Please record any partial days where the person was illegally present</i></p>

            <table className="illegal-presence-table">

                <thead>
                    <tr>
                        <td></td>
                        <td>From</td>
                        <td>To</td>
                        <td></td>
                        <td></td>
                        <td>Certainty</td>
                        <td></td>
                    </tr>
                </thead>

                <tbody>
                    {illegalPresenceRows.map((rowData: any, index: number) => (
                        <tr key={index}>
                            <td style={{width: 0, paddingRight: "0px"}}>{index + 1}</td>
                            <td style={{width: "110px"}}>
                                <Field.Date
                                    name="FromDate"
                                    value={rowData.FromDate}
                                    warning={false}
                                    minDate={periodStart}
                                    maxDate={rowData.ToDate ? rowData.ToDate : periodEnd}
                                    onChange={(e) => {
                                        handleIllegalPresenceChange(index, {name: "FromDate", value: new Date(e.target.value)})
                                    }} />
                            </td>
                            <td style={{width: "110px"}}>
                                <Field.Date
                                    name="ToDate"
                                    value={rowData.ToDate}
                                    warning={false}
                                    minDate={rowData.FromDate ? rowData.FromDate : periodStart}
                                    maxDate={periodEnd}
                                    onChange={(e) => {
                                        handleIllegalPresenceChange(index, {name: "ToDate", value: new Date(e.target.value)})
                                    }} />
                            </td>
                            <td></td>
                            <td></td>
                            <td style={{width: "70px"}}>
                                <Field.Select
                                    name="Certainty"
                                    value={rowData.Certainty}
                                    warning={false}
                                    onChange={(fieldOutput: FormFieldOutput) => handleIllegalPresenceChange(index, fieldOutput)}
                                    options={[
                                        {value: "CERTAIN", label: "Certain"},
                                        {value: "DOUBTFUL", label: "Likely"}
                                    ]} />
                            </td>
                            <td style={{width: "15px"}}>
                                <IconButton style={{padding: "0"}}>
                                    <Icons.X onClick={() => removeIllegalPresence(index)} />
                                </IconButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div style={{display: "flex", justifyContent: "space-between"}}>
                <Button variant="contained" className="btn btn-sm" onClick={() => addIllegalPresence()}>Add Illegal presence</Button>
                {/* <Button variant="contained" className="btn btn-sm" onClick={() => validate()}>Validate</Button> */}
                <Button variant="contained" className="btn btn-sm" disabled={submitting} onClick={() => attemptSubmit()}>Save and close</Button>
            </div>

            <ul style={{marginTop: "10px", paddingLeft: "15px"}} className="warning">
                {warnings.map((warning, i) => <li>{warning}</li>)}
            </ul>
        </div>
    )
})