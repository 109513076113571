import React from "react"
import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import { Button } from "@mui/material"
import QuestionnaireSelectField from "../questionnaire/QuestionnaireSelectField"

interface LoadQuestionnaireProps {
    caseUid: string;
}

export default observer(function LoadQuestionnaire ({caseUid}: LoadQuestionnaireProps) {

    const [questionnaireUid, setQuestionnaireUid] = React.useState(null)

    const run = async () => {
        store.AlertStore.Close()
        store.QuestionnaireStore.loadQuestionnaire(caseUid, questionnaireUid)
    }

    return (
        <div className="form">
            <h2>Load Questionnaire</h2>

            <div className="form-fields">
                {!questionnaireUid ? <p>Select a questionnaire and AORA will use it to create the person and then run the enquiry.</p> : null}

                <QuestionnaireSelectField
                    value={questionnaireUid}
                    onChange={(questionnaireUid) => setQuestionnaireUid(questionnaireUid)} />
            </div>

            <div className="form-buttons">
                    {questionnaireUid ? (
                        <>
                            <Button className="btn btn-sm" onClick={() => setQuestionnaireUid(null)}>
                                Remove selection
                            </Button>
                            <Button className="btn btn-sm" onClick={() => run()} color="success" variant="contained">
                                Use this questionnaire
                            </Button>
                        </>
                    ) : (
                        <Button className="btn btn-sm" onClick={() => store.AlertStore.Close()}>
                            Cancel
                        </Button>
                    )}
                </div>
        </div>
    )
})