import * as Yup from 'yup'
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"


export default function AutomationDontSubmitSection ({
    automationOption,
    onCancel = () => {},
    onSubmit = (values) => {}
}) {
    
    var formik = useFormik({
        initialValues: {
            submit_form: "false"
        },
        validationSchema: Yup.object({
            submit_form: Yup.string().required("This field is required")
        }),
        onSubmit: (values) => {
            onSubmit(values)
        }
    })

    var applyForm = automationOption.id === "SWV_COS_APPLY"

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-fields">
                {applyForm ? (
                    <div>
                        <p>Do you want the automation to <b>submit</b> the CoS form?</p>
                        <p>Choosing to submit <b>will</b> prompt the Home Office to review your application.</p>
                        <p>If you want to run a test automation so you can check the values first without submitting the CoS form, you can leave this setting switched off.</p>
                    </div>
                ) : (
                    <div>
                        <p>Do you want the automation to <b>save</b> the CoS form?</p>
                        <p>Choosing to save will allow you to find and amend this application in the SMS.</p>
                        <p>If you want to run a test automation so you can check the values first without submitting the CoS form, you can leave this setting switched off.</p>
                    </div>
                )}

                <Field.Switch
                    formik
                    label={applyForm ? "Submit CoS form" : "Save form"}
                    name="submit_form"
                    // helpText="You can leave this switched off to run a test automation. Doing this will allow you to check the values which will be used before submitting the final form."
                    value={formik.values.submit_form === "true"}
                    warning={formik.submitCount && formik.errors.submit_form ? formik.errors.submit_form : false}
                    onChange={(e) => formik.setFieldValue("submit_form", formik.values.submit_form === "true" ? "false" : "true")}  />
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => onCancel()}>Back</Button>
                
                <Button className="btn btn-sm" type="submit" color="success" variant="contained">
                    Confirm and start automation
                </Button>
            </div>
        </form>
    )
}