import { createTheme } from "@mui/material";

var color = `
$primary_dark:#243657;
$primary_main:#314A94;
$primary_light:#6D8DC1;
$primary_contrastText:#ffffff;

$secondary_dark:#D67D11;
$secondary_main:#FF910A;
$secondary_light:#FFAC47;
$secondary_contrastText:#ffffff;

$info_dark:#dcdcdc;
$info_main:#F2F2F2;
$info_light:#ffffff;
$info_contrastText:#434343;

$error_main:#c76466;
$error_dark:#c24b4e;
$error_light:#e87a7d;
$error_contrastText:#ffffff;

$success_dark:#548755;
$success_main:#6fa871;
$success_light:#8eba8f;
$success_contrastText:#ffffff;

$warning_dark:#D67D11;
$warning_main:#FF910A;
$warning_light:#FFAC47;
$warning_contrastText:#ffffff;`

const parseColorPalette = () => {

	var palette: any = {}
	var lines = color.match(/\$.*;/g)

	lines.forEach(line => {

		line = line.replace(";", "").replace("$", "")
		var key_arr = line.split(":")[0]
		var value_arr = line.split(":")[1]

		if (key_arr.length && value_arr.length) {

			var color_key = key_arr.split("_")[0]
			var shade_key = key_arr.split("_")[1]
			var value = value_arr.trim()

			if (!palette[color_key]) {
				palette[color_key] = {[shade_key]: value}
			} else {
				var color_dict = {...palette[color_key]}
				color_dict[shade_key] = value
				palette[color_key] = color_dict
			}
		}
	})

	return palette
}

function GenerateTheme () {

	var palette: any = parseColorPalette()

	// console.log("plalette", palette)

	return createTheme({

		palette: { ...palette },

		typography: {
			fontFamily: "-apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif",
			// fontFamily: '"Open Sans", "Trebuchet MS", "Gill Sans", "Verdana", "Tahoma", "Arial", "sans-serif"'
		},


		components: {
			MuiTextField: {
				defaultProps: {
					variant: 'standard',
				}
			},
			MuiSelect: {
				defaultProps: {
					variant: 'standard',
				}
			},
			MuiFormControl: {
				defaultProps: {
					variant: 'standard',
				}
			},
			MuiButton: {
				defaultProps: {
					size: 'small',
				},
			},
			MuiSwitch: {
				defaultProps: {
					size: 'small',
				}
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {width: 'auto',}
				}
			},
		},
	});
}


export const Theme = GenerateTheme()