import React, { useCallback } from 'react';
// import { loadFull } from 'tsparticles';
// import { Particles } from '@tsparticles/react';

export default () => {

  return <div id="animation"></div>
  // return (
  //   <Particles
  //     id="tsparticles"
  //     init={particlesInit}
  //     loaded={particlesLoaded}
  //     options={{
  //       background: {
  //         color: {
  //           value: "#0d47a1",
  //         },
  //       },
  //       fpsLimit: 60,
  //       interactivity: {
  //         events: {
  //           onClick: {
  //             enable: true,
  //             mode: "push",
  //           },
  //           onHover: {
  //             enable: true,
  //             mode: "repulse",
  //           },
  //           resize: true,
  //         },
  //         modes: {
  //           push: {
  //             quantity: 4,
  //           },
  //           repulse: {
  //             distance: 200,
  //             duration: 0.4,
  //           },
  //         },
  //       },
  //       particles: {
  //         color: {
  //           value: "#ffffff",
  //         },
  //         links: {
  //           color: "#ffffff",
  //           distance: 150,
  //           enable: true,
  //           opacity: 0.5,
  //           width: 1,
  //         },
  //         collisions: {
  //           enable: true,
  //         },
  //         move: {
  //           direction: "none",
  //           enable: true,
  //           outModes: {
  //             default: "bounce",
  //           },
  //           random: false,
  //           speed: 6,
  //           straight: false,
  //         },
  //         number: {
  //           density: {
  //             enable: true,
  //             area: 800,
  //           },
  //           value: 80,
  //         },
  //         opacity: {
  //           value: 0.5,
  //         },
  //         shape: {
  //           type: "circle",
  //         },
  //         size: {
  //           value: { min: 1, max: 5 },
  //         },
  //       },
  //       detectRetina: true,
  //     }}
  //   />
  // );
}