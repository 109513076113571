import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import UserFormOnly from "./UserForms"
import { AlertModal, DisplayDictionary } from "../../.."
import { store } from "../../../stores/Store"
import Account from "../../../models/Account"
import { format } from "date-fns"


interface AccountEditFormProps {
    account: Account;
    onSuccess?: () => void;
}


interface AccountCreateFormProps {
    initialValues?: any;
    guidance?: string;
    onSuccess?: (node) => void;
    omitTitle?: boolean;
}

export function NewSponsorClientAccountForm ({onSuccess = (node) => {}}) {
    return (
        <AccountCreateForm
            initialValues={{
                accountType: "sponsor",
                countryId: "UK"
            }}
            onSuccess={(node) => {
                console.log("New sponsor added", node)
                store.NodeStore.navigateNode(node?.uid)
                store.NodeStore.refreshAccounts()
                store.AlertStore.Close()
                onSuccess(node)
            }} />
    )
}

export function AccountEditForm ({account, onSuccess = () => {}}: AccountEditFormProps) {

    const handleSubmit = async (values) => {
        
        var isSponsor = account.typeName === "Aora.Nationality.Data.SponsorAccountNode"
        
        return await Ajax.Node.Edit(isSponsor ? "sponsor" : "account", values).then(() => onSuccess())
    }

    if (!account) return <h4>Error loading form</h4>

    console.log({...account})
    return (
        <AccountFormOnly
            omitTitle
            initialValues={account}
            handleCancel={() => store.AlertStore.Close()}
            handleSubmit={(values) => handleSubmit(values)} />
    )
}


export function AccountCreateForm ({
    guidance = null, 
    initialValues = null, 
    onSuccess = (node) => {},
    omitTitle = false,
}: AccountCreateFormProps) {

    const [step, setStep] = React.useState<0|1>(0)
    const [newAccount, setNewAccount] = React.useState<Account>(null)

    const handleSubmit = async (userValues) => {

        newAccount.childrenObjects = [{...userValues}]

        return await Ajax.Node.Create(newAccount.accountType === "sponsor" ? "sponsor" : "account", newAccount.parent.uid, newAccount).then((response) => {
            onSuccess(response.data)
        })
    }

    return (
        <div>
            <AccountFormOnly
                omitTitle={omitTitle}
                guidance={guidance}
                hidden={step !== 0}
                initialValues={initialValues}
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={(values) => {
                    setNewAccount(values)
                    setStep(1)
                    return Promise.resolve()
                }} />

            <UserFormOnly
                guidance="Enter the details of the account contact, you can decide whether they can login either now or later."
                hidden={step !== 1}
                includePassword={initialValues.accountType === "customer"}
                includeUsername={initialValues.accountType === "customer"}
                requireCredentials={initialValues.accountType === "customer"}
                handleCancel={() => setStep(0)}
                handleSubmit={(values) => handleSubmit(values)} />
        </div>
    )
}


export default function AccountFormOnly ({
    handleSubmit, 
    handleCancel, 
    guidance = null,
    initialValues = null, 
    hidden = false,
    omitTitle = false
}) {

    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    var defaultInitialValues = {
        accountName: "",
        accountType: "",
        customerType: "",
        addressLine1: "",
        city: "",
        postcode: "",
        countryId: "",
        phone: "",
        regulator: "",
        regulatorRegistrationNumber: "",
        sponsorLicenceNum: "",
        sponsorStartDate: "",
        // sponsorExpiryDate: "",
        parentUid: initialValues.accountType === "customer" ? "ACCOUNT_-1" : store.AppStore.sessionInfo.parentAccount.uid,
    }

    // Allow some or all default values to be overridden
    if (initialValues) {
        initialValues = {...defaultInitialValues, ...initialValues}
    } else {
        initialValues = defaultInitialValues
    }

    var validationSchema = {
        accountName: Yup.string().required("This field is required"),
        addressLine1: Yup.string().required("This field is required"),
        city: Yup.string().required("This field is required"),
        postcode: Yup.string().required("This field is required"),
        countryId: Yup.string().required("This field is required"),
        phone: Yup.string().matches(
            /^\s*(?:\+?(\d{1,4}))?([-. (]*(\d{2,4})[-. )]*)?((\d{2,4})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
            "A valid phone number is required"
        ).required("This field is required"),
        customerType: Yup.string().required("This field is required"),

        organisationType: Yup.string().when("customerType", {
            is: (customerType) => customerType === "N", 
            then: () => Yup.string().required("This field is required for private sector organisations"), 
            otherwise: () => Yup.string().nullable()
        }),

        sponsorLicenceNum: Yup.string().when("accountType", {
            is: (accountType) => accountType === "sponsor", 
            then: () => Yup.string().required("This field is required").max(15, "Licence number must be less than 15 characters"), 
            otherwise: () => Yup.string().nullable()
        }),

        sponsorStartDate: Yup.date().when("accountType", {
            is: (accountType) => accountType === "sponsor", 
            then: () => Yup.date().required("This field is required"), 
            otherwise: () => Yup.date().nullable()
        }),
        
        // sponsorExpiryDate: Yup.string().when("accountType", {is: "sponsor", 
        //     then: Yup.string().required("This field is required"), 
        //     otherwise: Yup.string().nullable()}),

        regulator: Yup.string().when("accountType", {
            is: (accountType) => accountType === "customer", 
            then: () => Yup.string().required("This field is required"), 
            otherwise: () => Yup.string().nullable()}),

        regulatorRegistrationNumber: Yup.string().when("accountType", {
            is: (accountType) => accountType === "customer", 
            then: () => Yup.string().required("This field is required"), 
            otherwise: () => Yup.string().nullable()}),
    }

    var formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        onSubmit: async (values) => {

            // Convert parentUid to node reference
            values.parent = {uid: values.parentUid}
            setError("")
            setSubmitting(true)

            return await handleSubmit(values).catch((response) => {

                setError(response.data?.detail ? response.data?.detail : "Something went wrong!")
                response.errors && formik.setErrors(response.errors)

            }).finally(() => setSubmitting(false))
        }
    })

    // const toggleSponsor = () => {
    //     var newAccountType = formik.values.accountType === "sponsor" ? "client" : "sponsor"
    //     var newValues = {
    //         ...formik.values, 
    //         accountType: newAccountType,
    //         countryId: newAccountType === "sponsor" ? "UK" : null,
    //     }
    //     formik.setValues(newValues) 
    // }

    if (hidden) return null

    // var accountTypeHelp = [
    //     "A Client is an individual that you set up for Nationality enquiries only when you want your client to enter the data for the enquiry.", 
    //     "A Sponsor Client is a UK business which has a licence to sponsor Skilled Workers. Required for Skilled Worker enquiries."
    // ]

    // if (formik.values.accountType === "sponsor") {
    //     accountTypeHelp.reverse()
    // }

    return (
        <form autoComplete="off" className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit}>

            {!omitTitle 
                ? <h2>Create new <span style={{textTransform: "capitalize"}}>{formik.values.accountType}</span></h2>
                : null}

            {guidance ? <p className="font-sm">{guidance}</p> : null}

            {/* {formik.values.accountType !== "customer" ? (
                <Field.Switch 
                    style={{margin: "auto"}}
                    warning={false}
                    leftLabel="Private Client" 
                    label={<><b>Sponsor</b> (Business)</>} 
                    value={formik.values.accountType === "sponsor"} 
                    onChange={() => toggleSponsor()} />
            ) : null} */}

            {/* {formik.values.accountType !== "customer" ? (
                <div>
                    {accountTypeHelp.map((helpText, i) => <p key={i} className="font-sm">{helpText}</p>)}
                </div>
            ) : null} */}

            {formik.values.accountType === "sponsor" ? (
                <p className="font-sm">A Sponsor Client is a UK business which has a licence to sponsor Skilled Workers. Required for Skilled Worker enquiries.</p>
            ) : null}

            <div className="form-fields" style={{minHeight: "200px", maxHeight: "55vh", paddingRight: "5px"}}>

                <Field.Text
                    formik
                    autoFocus={true}
                    label={<><span style={{textTransform: "capitalize"}}>{formik.values.accountType}</span> name</>}
                    name="accountName"
                    value={formik.values.accountName}
                    warning={formik.submitCount && formik.errors.accountName ? formik.errors.accountName : false}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    label="Address"
                    name="addressLine1"
                    value={formik.values.addressLine1}
                    warning={formik.submitCount && formik.errors.addressLine1 ? formik.errors.addressLine1 : false}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    label="City"
                    name="city"
                    value={formik.values.city}
                    warning={formik.submitCount && formik.errors.city ? formik.errors.city : false}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    label="Postcode"
                    name="postcode"
                    value={formik.values.postcode}
                    warning={formik.submitCount && formik.errors.postcode ? formik.errors.postcode : false}
                    onChange={formik.handleChange} />

                {formik.values.accountType !== "sponsor" ? <Field.MinimisedLargeListSelect
                    formik
                    multiple={false}
                    label="Country"
                    name="countryId"
                    disabled={formik.values.accountType === "sponsor"}
                    value={formik.values.countryId}
                    warning={formik.submitCount && formik.errors.countryId ? formik.errors.countryId : false}
                    onChange={formik.handleChange}
                    options={DisplayDictionary("countries", true)} />
                : null}

                <Field.Text
                    formik
                    label="Phone number"
                    name="phone"
                    value={formik.values.phone}
                    warning={formik.submitCount && formik.errors.phone ? formik.errors.phone : false}
                    onChange={formik.handleChange} />

                <Field.Select
                    formik
                    name="customerType"
                    label="Organisation type"
                    value={formik.values.customerType}
                    options={DisplayDictionary("customer_type", true)} 
                    warning={formik.submitCount && formik.errors.customerType ? formik.errors.customerType : false}
                    onChange={(e) => {
                        formik.setFieldValue("customerType", e.target.value)
                        if (e.target.value !== "N") {
                            formik.setFieldValue("organisationType", "")
                        }
                        formik.validateForm()
                    }} />

                {formik.values.customerType === "N" ? (
                    <Field.Select
                        formik
                        label="Private sector organisation type"
                        name="organisationType"
                        value={formik.values.organisationType}
                        warning={formik.submitCount && formik.errors.organisationType ? formik.errors.organisationType : false}
                        onChange={formik.handleChange} 
                        options={DisplayDictionary("org_type", true)} />
                ) : null}

                {/* {!initialValues.uid && (formik.values.countryId === "UK" && formik.values.accountType !== "customer" && formik.values.customerType !== "I") ? <Field.Checkbox 
                    formik 
                    label="Add sponsor licence info" 
                    helpText="Required for Skilled Worker visa applications. The new account will be categorised as a 'sponsor account'." 
                    value={formik.values.accountType === "sponsor"} onChange={(e) => {
                        formik.setValues({...formik.values, accountType: e.target.checked ? "sponsor" : "client"}) 
                    }} /> 
                : null} */}

                {formik.values.accountType === "sponsor" ? (
                    <>
                        <Field.Text
                            formik
                            label="Sponsor licence number"
                            name="sponsorLicenceNum"
                            value={formik.values.sponsorLicenceNum}
                            warning={formik.submitCount && formik.errors.sponsorLicenceNum ? formik.errors.sponsorLicenceNum : false}
                            onChange={formik.handleChange} />
                        
                        <Field.Date
                            label="Sponsor licence issue date"
                            name="sponsorStartDate"
                            minDate="2000-01-01"
                            maxDate={format(new Date(), "yyyy-MM-dd")}
                            value={formik.values.sponsorStartDate}
                            warning={formik.submitCount && formik.errors.sponsorStartDate ? formik.errors.sponsorStartDate : false}
                            onChange={(e) => {
                                formik.setFieldValue("sponsorStartDate", e.target.value)
                            }} />     
                        
                        {/* <Field.Date
                            formik
                            label="Sponsor licence expiry date"
                            name="sponsorExpiryDate"
                            minDate={new Date()}
                            maxDate={new Date("2090-01-01")}
                            value={formik.values.sponsorExpiryDate}
                            warning={formik.submitCount && formik.errors.sponsorExpiryDate ? formik.errors.sponsorExpiryDate : false}
                            onChange={formik.handleChange} />       */}
                    </>
                ) : <span></span>}
                
                {formik.values.accountType === "customer" ? (
                    <>
                        <Field.Select
                            formik
                            label="Regulator"
                            name="regulator"
                            value={formik.values.regulator}
                            options={DisplayDictionary("ni_regulator", true)}
                            warning={formik.submitCount && formik.errors.regulator ? formik.errors.regulator : false}
                            onChange={formik.handleChange} />

                        <Field.Text
                            formik
                            label="Regulator reference number"
                            name="regulatorRegistrationNumber"
                            value={formik.values.regulatorRegistrationNumber}
                            warning={formik.submitCount && formik.errors.regulatorRegistrationNumber ? formik.errors.regulatorRegistrationNumber : false}
                            onChange={formik.handleChange} />
                    </>
                ) : <span></span>}

                {store.AppStore.checkJwtRole("sysuser") && formik.values.accountType !== "customer" ? (
                    <Field.Select
                        formik
                        label="Parent account"
                        name="parentUid"
                        disabled={initialValues.uid}
                        value={formik.values.parentUid}
                        options={store.NodeStore.accounts.filter(s => !s.parent).map((a) => (
                            {label: a.label, value: a.uid}
                        ))}
                        warning={formik.submitCount && formik.errors.parentUid ? formik.errors.parentUid : false}
                        onChange={formik.handleChange} />
                ) : <span></span>}
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => handleCancel()}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm"
                    disabled={formik.isSubmitting}>
                    Submit
                </Button>
            </div>

        </form>
    )
}