import React from "react"
import Ajax from "../../Ajax"
import { store } from "../../stores/Store"
import Field from "./fields/Field"
import { Button } from "@mui/material"
import { Alert } from "../.."

export default function PopupRequestForm ({pr}) {

    const [inputValue, setInputValue] = React.useState("")
    const [submitting, setSubmitting] = React.useState(false)
    const [error, setError] = React.useState(null)

    const freeText = pr.validResponses.find(option => option === "TEXT")

    var selectOptions = []
    pr.validResponses.forEach(value => {
        if (value.includes("=>")) {
            selectOptions.push({value: value.split("=>")[0], label: value.split("=>")[1]})
        }
    })

    const submitValue = (value) => {
        setError(false)
        setSubmitting(true)
        Ajax.Message.RespondPopup(value).catch((response) => {
            Alert({message: response.data?.detail ? response.data?.detail : "There was an error"})
        }).finally(() => {
            store.AlertStore.Close()
        })
    }

    const cancel = async () => {
        setError(false)
        setSubmitting(true)
        await Ajax.Message.CancelPopup().catch((response) => {
            Alert({message: response.data?.detail ? response.data?.detail : "There was an error"})
        }).finally(() => {
            store.AlertStore.Close()
        })
    }

    return (
        <form className="form" onSubmit={(e) => {
            e.preventDefault()
            if (freeText) {
                if (inputValue) {
                    submitValue(inputValue)
                } else {
                    setError("Please provide a value")
                }
            }
        }}>

            {pr.title ? <h2>{pr.title}</h2> : null}
            
            <div dangerouslySetInnerHTML={
                { __html: pr.mainText }
            } />

            <div>

                {freeText ? (
                    <Field.Text 
                        formik
                        autoFocus
                        value={inputValue} 
                        placeholder="Enter text here..." 
                        onChange={(e) => {
                            setError(null)
                            setInputValue(e.target.value)
                        }} />
                ) : null}

                {selectOptions.length ? (
                    <Field.Select 
                        formik
                        autoFocus
                        value={inputValue}
                        label="Select an option"
                        options={selectOptions}
                        onChange={(e) => {
                            setError(null)
                            setInputValue(e.target.value)
                        }} />
                ) : null}

                {error ? <div className="warning">{error}</div> : null}
            </div>
            
            <div className="form-buttons">
                {store.AutomationStore.runningAutomation ? (
                    <Button 
                        disabled={submitting} 
                        className="btn btn-sm"
                        onClick={() => cancel()}>
                        Cancel
                    </Button>
                ) : null}

                {freeText || selectOptions.length ? (
                    <Button 
                        disabled={submitting} 
                        className="btn btn-sm" 
                        variant="contained"
                        color="success" 
                        onClick={() => submitValue(inputValue)}>
                        Submit
                    </Button>
                ) : null}

                {pr.validResponses.filter(o => o !== "TEXT" && !selectOptions.length)?.map((option, i) => (
                    <Button 
                        key={i} 
                        disabled={submitting} 
                        className="btn btn-sm" 
                        variant="contained" 
                        onClick={() => submitValue(option)}>
                        {option}
                    </Button>
                ))}
            </div>

        </form>
    )
}