import AutomationMenuOptions from "./AutomationMenuOptions"
import AutomationReviewDetails from "./AutomationReviewDetails"
import AutomationResumeDetails from "./AutomationResumeDetails"
import ViewQuestionnaire from "../../../questionnaire_mgmt/ViewQuestionnaire"
import React from "react"
import { Button, Tab, Tabs } from "@mui/material"
import { observer } from "mobx-react-lite"
import { Automator } from "../../../../../models/Automator"
import { store } from "../../../../../stores/Store"
import { AlertModal, DisplayValue } from "../../../../.."
import AutomationAnswers from "./AutomationAnswers"


interface AutomationDetail {
    label: string;
    automator?: Automator;
}


export default observer(function AutomationDetail (props: AutomationDetail) {

    const [tab, setTab] = React.useState<"details" | "answers">("details")

    // The automator passed from props won't get updated, so check enquiryAutomations for the updated automator.
    var enquiryAutomation = store.AutomationStore.enquiryAutomations.find(a => a.automatorJson.includes(props.automator.id))
    const automator = enquiryAutomation ? JSON.parse(enquiryAutomation.automatorJson) : props.automator

    if (!automator) return <h4>No automator</h4>

    return (        
        <div className="automation-review">
            <div className="header">
                <h2>Automation Review <AutomationMenuOptions automator={automator} /></h2>
                
                <Tabs
                    value={tab}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="fullWidth"
                    style={{width: "100%", borderBottom: "1px solid lightgrey"}}
                    className="automation-review-tab">
                    <Tab onClick={() => setTab("details")} value="details" label="Details" />
                    <Tab onClick={() => setTab("answers")} value="answers" label="Answers" />
                </Tabs>
            </div>

            <div className="automation-review-columns">
                <div className={tab === "details" ? "automation-review-details" : "automation-review-details collapse"}>
                    <div>
                        <p>{props.label}</p>
                        <AutomationStatus automator={automator} />
                    </div>
                    
                    <div>
                        <h4>Filled {automator.current_page + 1} out of {automator.total_pages} pages.</h4>
                        <AutomationReviewDetails automator={automator} />
                    </div>
                    
                    <div>
                        <h4 style={{margin: "8px 0"}}>Resume details</h4>
                        <AutomationResumeDetails automator={automator} />
                    </div>

                    {automator.profile.questionnaires ? (
                        <div>
                            <h4 style={{margin: "8px 0"}}>Questionnaires used</h4>
                            
                            <ul style={{margin: 0}}>
                                {automator.profile.questionnaires.map((qRef, i) => (
                                    <li key={i}>
                                        <small
                                            className="link"
                                            onClick={() => AlertModal({width: "700px", body: <ViewQuestionnaire uid={qRef.value} />})} >
                                            {qRef.label}
                                        </small>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                </div>

                <div className="divider"></div>

                <div style={{width: "100%", maxHeight: "80vh"}} className={tab === "answers" ? "" : "collapse"}>
                    <AutomationAnswers automator={automator} />
                </div>
            </div>
        </div>
    )
})


function AutomationStatus ({automator}) {

    const { statusColor, statusDescription, cancel } = store.AutomationStore
    const [cancelling, setCancelling] = React.useState(false)

    var color = statusColor(automator.status)
    var description = statusDescription(automator)
    var status = DisplayValue("automation_status", automator.status)

    const onCancel = async () => {
        setCancelling(true)
        await cancel()
    }

    return (
        <div style={{color: color, margin: "0 0 10px 12px"}}>
            <div style={{display: "flex", alignItems: "center", columnGap: "10px"}}>
                <span><b>{automator.status === "RUNNING" && cancelling ? "Cancelling..." : status}</b></span>
                
                {automator.status === "RUNNING" ? (
                    <Button 
                        onClick={() => onCancel()} 
                        className="btn btn-xs" 
                        color="error" 
                        disabled={cancelling}
                        variant="contained">
                        Cancel
                    </Button>
                ) : null}
            </div>
            
            <small>{description}</small>

            {automator?.validation_messages?.length ? (
                <div>
                    {automator?.validation_messages.map((message, i) => (
                        <small key={i} className="warning" style={{margin: "10px", display: "list-item"}}>{message}</small>
                    ))}
                </div>
            ) : null}
        </div>
    )
}