import { Switch } from "@mui/material";
import { InputFieldProps } from "../../../models/Interfaces";

interface CheckboxFieldProps extends InputFieldProps {
    value: boolean;
    className?: string;
}

interface SwitchFieldProps extends CheckboxFieldProps {
    leftLabel?: string;
}

export default function CheckboxField ({
    name = "",
    value,
    label,
    onChange,
    warning = "",
    formik = false,
    disabled = false,
    className="",
    helpText = ""
}: CheckboxFieldProps) {

    return (
        <div className={`form-field field-checkbox ${className}`}>
            <label>
                <input
                    name={name} 
                    checked={value}
                    type="checkbox"
                    disabled={disabled}
                    onChange={(e) => {
                        e.target.value = e.target.checked.toString()
                        formik ? onChange(e) : onChange({name: name, value: e.target.checked ? "true" : "false"})
                    }} 
                />
                <span className="field-checkbox-label-content">{label}</span>
            </label>
            {helpText ? <span className="help-text">{helpText}</span> : null}
            
            {warning !== false && typeof warning === "string" ? <span className="warning">{warning}</span> : null}
        </div>
    )
}

export function SwitchField ({
    name,
    value,
    label,
    leftLabel = null,
    onChange,
    warning = "",
    formik = false,
    disabled = false,
    className="",
    helpText = "",
    style = {}
}: SwitchFieldProps) {
    return (
        <div className={`form-field field-checkbox ${className}`} style={style}>
            <label>
                {leftLabel ? <span>{leftLabel}</span> : null}
                <Switch
                    name={name} 
                    checked={value}
                    type="checkbox"
                    size="small"
                    disabled={disabled}
                    value={!!value}
                    onChange={(e) => {
                        formik ? onChange(e) : onChange({name: name, value: e.target.checked ? "true" : "false"})
                    }} 
                />
                <span>{label}</span>
            </label>

            {helpText ? <small>{helpText}</small> : null}

            {warning !== false && typeof warning === "string" ? <span className="warning">{warning}</span> : null}
        </div>
    )
}