import { Button } from "@mui/material";
import { store } from "../../../stores/Store";

export default function MicrosoftOAuthForm () {

    // Get OAuth cookie variables
    var codeChallenge = store.AppStore.getCookie("AORA-OAuth-PKCE-code-challenge")
    var oauthProvider = store.AppStore.getCookie("AORA-OAuth-provider")
    var clientId = store.AppStore.getCookie("AORA-OAuth-client-id")
    var tenantId = store.AppStore.getCookie("AORA-OAuth-tenant-id")
    var redirectUri = store.AppStore.getCookie("AORA-OAuth-redirect-uri")
    redirectUri = decodeURI(redirectUri)
    var oAuthEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?` +
                        `client_id=${clientId}&` +
                        `state=${oauthProvider}&` +
                        `redirect_uri=${redirectUri}&` +
                        "code_challenge=" + codeChallenge + "&" +
                        "scope=https%3A%2F%2Fgraph.microsoft.com%2Fopenid&" +
                        "response_type=code&" +
                        "code_challenge_method=S256"

    return (
        <div style={{width: "300px"}}>
            <h1 style={{fontSize: "30px"}}>Sign in</h1>
            <p>Your account is configured to connect using Microsoft, click the button bellow to sign in.</p>
            <br/>
            <Button
                className="btn btn-md"
                color="info"
                variant="contained"
                style={{textTransform: "none"}}
                href={oAuthEndpoint}>
                Sign in with 
                <img alt="M" height="14" src="/images/microsoft.png" />
            </Button>
        </div>
    )
}