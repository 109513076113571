import { FormikErrors } from "formik";
import { ReactElement } from "react";

export interface Questionnaire {    
    uid: string;
    displayName: string;
    respondent: Respondent;
    template: Template;
    json: any;
    answers: any;
    accessCode: string;
    link: string;
    notificationEmail: string;
    locked: boolean;
    createdAt: string;
    expiresAt: string;
    updatedAt: string;
}

export interface Respondent {
    uid: string;
    advisorAccount: string;
    respondentName: string;
    respondentEmail: string;
}

export interface Template {
    key: string;
    name: string;
    json: any;
    advisor: string;
    initialValues: any;
    insertedAt: string;
    active: boolean;
}

export interface FormFieldOutput {
    name: string;
    value: any;
}

export interface FormSectionProps {
    formId: string;
    onSubmit: Function;
    section: any;
}

export interface NodeReference{
    name: string;
    uid: string;
}

export class ErrorNode {
    
    typeName?: string;
    title?: string;
    detail?: string;
    status?: number;

    constructor (obj: Object) {
        Object.assign(this, obj)
    }
}

export interface CtaInfo {
    buttonText: string;
    action: any;
    helpText?: string;
    color?: "primary" | "secondary" | "success" | "error" | "info";
}

export interface InputFieldProps {
    id?: string;
    value?: any;
    name?: string;
    label?: ReactElement | string;
    formik?: boolean;
    warning?: boolean | string | string[] | FormikErrors<any> | FormikErrors<any>[];
    helpText?: string;
    style?: any;
    variant?: "standard" | "outlined" | "filled";
    disabled?: boolean;
    required?: boolean;
    autoFocus?: boolean;
    onChange?: Function;
    placeholder?: string;
    autoComplete?: boolean;
    disableUnderline?: boolean;
}

export interface SessionInfo {
    instanceName: string;
    hostAddress: string;
    version: string;
    versionDate: string;

    sessionId: number;
    userUid: string;
    username: string;
    userEmail: string;
    lastLogin: string;
    lastEnquiryUid: string;

    parentAccount: NodeReference;
    displayDictionary: any;
}

export interface NodeReference {
    uid: string;
    name: string;
    typeName?: string;
}

export interface AlertArgs {
    message: string;
    color?: "default" | "error" | "success" | "info" | "warning";
    actions?: AlertAction[];
    persist?: boolean;
    closable?: boolean;
    truncRevealer?: boolean;
    verticalAnchor?: "top" | "bottom";
    horizontalAnchor?: "left" | "right" | "center";
}

export interface AlertControl {
    alert: Function;
    dismiss: Function;
}

export interface AlertAction {
	label: string | React.ReactElement;
	action?: Function;
    disabled?: boolean;
	color?: "primary" | "error" | "inherit" | "secondary" | "success" | "info" | "warning";
	variant?: "contained" | "text" | 'outlined';
}