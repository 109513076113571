import * as Yup from 'yup'
import { useFormik } from "formik"
import Field from "../fields/Field"
import { DisplayValue } from '../../..'
import { FormSectionProps } from '../../../models/Interfaces'


export default function EnquiryStatusesFormSection ({formId, onSubmit, section}: FormSectionProps) {

    var formik = useFormik({

        initialValues: { statuses: [] },

        validationSchema: Yup.object({
            statuses: Yup.array().min(1, `You must select a type of enquiry`)
        }),

        onSubmit: (values) => {
            var newSection = {...section}
            
            newSection.childrenObjects = section.childrenObjects.map(status => {
                status.selected = values.statuses.includes(status.uid)
                return status
            })
            
            onSubmit(newSection)
        }
    })

    // Transform flat list of statuses into a tree structured by type
    var nationalityOptionsNested = []
    var typeOrder = ["SW", "CITIZEN", "PROTÉGÉ", "PR_STATUS", "TR_STATUS"]

    // Add each status to tree
    typeOrder.forEach((category => {

        var statuses = section.childrenObjects.filter(nat => category.includes(nat.category))

        if (category === "PR_STATUS") {
            statuses = statuses.reverse()
        }

        if (statuses?.length) {
            nationalityOptionsNested.push({
                label: DisplayValue("status_tree_category", category),
                value: category,
                children: statuses.map(status => {
                    return {
                        value: status.uid,
                        label: status.label,
                        // disabled: disabled
                    }
                })
            })
        }
    }))

    return (
        <form id={formId} className="form-fields" onSubmit={formik.handleSubmit}>
            
            <p>Select the legal statuses you want to investigate</p>
            
            <div style={{maxHeight: "300px", overflow: "auto"}}>
                {nationalityOptionsNested.map((statusGroup, i) => (
                    <div key={i}>
                        <h4>{statusGroup.label}</h4>
                        <div style={{margin: "10px", display: "grid", gap: "5px"}}>
                            {statusGroup.children.map((option, o) => (
                                <Field.Checkbox 
                                    formik
                                    name={option.value} 
                                    label={option.label} 
                                    warning={false}
                                    value={formik.values.statuses.includes(option.value)}
                                    onChange={(e) => formik.setFieldValue("statuses", [option.value])} />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
                
            {formik.errors.statuses && typeof formik.errors.statuses === "string" ? (
                <div className="form-message warning">{formik.errors.statuses}</div>
            ) : null}

        </form>
    )
}



