import React from "react"
import { Icons } from "../misc/Icons"
import { makeAutoObservable } from "mobx"
import { observer } from "mobx-react-lite"
import ClearIcon from '@mui/icons-material/Clear'
import { ProviderContext, useSnackbar } from "notistack"
import { Backdrop, Button, Dialog, IconButton } from "@mui/material"
import { AlertAction, AlertArgs, AlertControl } from "../models/Interfaces"
import { store } from "./Store"

interface AlertModalProps {
    id?: string;
    title?: string;
    body: string | React.ReactElement;
    width?: string;
    actions?: AlertAction[];
    requireAction?: boolean;
    size?: "auto" | "xs" | "sm" | "md" | "lg" | "xl";
    code?: boolean;
}

class AlertModal {

    id: string = Math.random().toString();
    title: string = "";
    body: string | React.ReactElement = "";
    code: boolean = false;
    size: string = "sm"
    width: string = null
    actions: AlertAction[] = [];
    requireAction: boolean = false;

    constructor (props: AlertModalProps) {
        Object.assign(this, props)
    }

    Close = () => {
        store.AlertStore.Close(this.id)
    }

    render: React.FunctionComponent = observer(() => {

        var styles = this.width ? {maxWidth: this.width} : {}

        return (
            <Dialog
                open={true}
                hideBackdrop
                onKeyDown={(e) => {
                    if (e.key === "Escape" && !this.requireAction) {
                        this.Close()
                    }
                }}>

                <div className={`card card-${this.size} ${this.code ? "card-code" : null}`} style={styles}>
                    {this.requireAction === false ? (
                        <IconButton aria-label="Close" className="modal-close" onClick={() => this.Close() }>
                            <Icons.X />
                        </IconButton>
                    ) : null}

                    {this.title ? (
                        <div className="card-header">
                            <h2>{this.title}</h2>
                        </div>
                    ) : null}

                    <div className="card-body">{this.body}</div>

                    {this.actions?.length ? (
                        <div className="card-footer">
                            {this.actions.map(({
                                label,
                                color = "primary",
                                variant = "contained",
                                disabled = false,
                                action = () => {}
                            }: AlertAction, i) => (
                                <Button
                                    key={i}
                                    className="btn btn-md"
                                    color={color}
                                    variant={variant}
                                    disabled={disabled}
                                    onClick={() => {
                                        action()
                                        this.Close()
                                    }} >
                                    {label}
                                </Button>
                            ))}
                        </div>
                    ) : null}

                </div>
            </Dialog>
        )
    })
}


export default class AlertStore {

    snackbar: ProviderContext | null = null;
    alertModals: AlertModal[] = [];

    constructor() {
        makeAutoObservable(this)
    }

    Close = async (id: string = null) => {
        
        if (id === null) {
            var modals = this.alertModals
            modals.splice(-1)
            this.alertModals = modals
        } else {
            this.alertModals = this.alertModals.filter((am) => { return am.id !== id })
        }

        return Promise.resolve()
    }

    AlertModal = (props: AlertModalProps): void => {

        // Remove any modals with the same ID to avoid duplicates
        var exising_modal = this.alertModals.find((am) => props.id === am.id)
        console.log(props, exising_modal)

        if (!exising_modal) {
            this.alertModals.push(new AlertModal(props))
        }
    }

    RenderAlertModals: React.FunctionComponent = observer(() => {

        // Use the RenderAlertModals function to fire the useSnackbar hook, this must be done
        // in a functional component. It is then assigned to the store to be used globally
        // Library documentation: https://iamhosseindhv.com/notistack/demos
	    this.snackbar = useSnackbar()

        return (
            <>
                {this.alertModals.length > 0 ? <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} /> : null}
                {this.alertModals.map((am, i) => <am.render key={i} />)}
            </>
        )
    })

    Alert = ({
        message,
        color = "info",
        actions = [],
        persist = false,
        closable = true,
        truncRevealer = true,
        verticalAnchor = "bottom",
        horizontalAnchor = "right",
    }: AlertArgs): AlertControl => {

        if (!message){
            message = "No message"
        }

        if (persist && closable) {
            var closerColor = color === "info" ? "grey" : "white"
            actions = [...actions, { variant: "text", color: "info", label: <Icons.X style={{color: closerColor}} />}]
        }

        if (truncRevealer && message.length > 40) {
            var fullMessage = message
            actions.push({label: "Full message", action: () => this.AlertModal({body: fullMessage})})
            message = message.substring(0, 46) + "..."
        }

        const alert = (alertColor: string) => this.snackbar.enqueueSnackbar(message, {
            action: () => {
                return actions.map(({
                    label,
                    action = () => this.snackbar.closeSnackbar(alert_key),
                    color = alertColor === "error" ? "info" : "primary",
                    variant = "contained"
                }: AlertAction, i: number) => (
                    <Button
                        key={i}
                        className="btn btn-xs"
                        variant={variant}
                        color={color}
                        onClick={() => {
                            action()
                            // this.snackbar.closeSnackbar(alert_key)
                        }}>
                        {label}
                    </Button>
                ))
            },
            variant: color,
            anchorOrigin: { vertical: verticalAnchor, horizontal: horizontalAnchor },
            persist: persist,
            preventDuplicate: false
        })

        const alert_key = alert(color)

        const dismiss = () => this.snackbar.closeSnackbar(alert_key)

        return {dismiss: dismiss, alert: alert}
    }
}