import { observer } from "mobx-react-lite"
import Panel from "../../../layout/Panel"
import PanelLabel from "../../../layout/PanelLabel"
import PanelHeader from "../../../layout/PanelHeader"
import PanelBody from "../../../layout/PanelBody"
import { DetailPanelProps } from "./DetailPanel"
import { store } from "../../../../stores/Store"


export default observer(function ErrorDetail ({index}: DetailPanelProps) {  

    const {NodeStore} = store
    const node = NodeStore.leafNode

    return (
        <Panel index={index} id="DETAIL" className="detail" width="100%" loadingContent={NodeStore.loadingLeafNode}>

            <PanelLabel title="Error detail" />

            <PanelHeader>
                <h4>{node.label ? node.label : "Something went wrong there"}</h4>
            </PanelHeader>

            <PanelBody>
                <p>{node.detail ? node.detail : "We couldn't get the resource your are looking for."}</p>
            </PanelBody>

        </Panel>
    )
})