//             /  //          / // /// //    |  // // //           /  //
//            /  ////       /  //    \  //   |  //   \  //        /  ////
//           /  //  //     |  //      \  //  |  //    |  //      /  //  //
//          /  //    //    |  //       | //  |  //   /  //      /  //    //
//         /  /// // ///   |  //       | //  |  // // //       /  /// // ///
//        /  //_______ //  |  //      /  //  |  //  \  //     /  //_______ //
//       /  //       \  //  \  //    /  //   |  //   \  //   /  //       \  //
//       \_//         \__//  \__// // //     |__//    \__//  \_//         \__//
import './App.scss'
import App from './App'
import { Theme } from './misc/Theme'
import { createRoot } from 'react-dom/client'
import { SnackbarProvider } from 'notistack'
import ErrorBoundary from './misc/ErrorBoundary'
import { store, storeContext } from './stores/Store'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, useNavigate, useLocation, useSearchParams, useParams } from 'react-router-dom'


export const DisplayDictionary = store.AppStore.DisplayDictionary

export const DisplayValue = store.AppStore.DisplayValue

export const AlertModal = store.AlertStore.AlertModal

export const CloseModal = store.AlertStore.Close

export const Alert = store.AlertStore.Alert

// Use inner and outer roots, this is so that navigation hooks can be called in the inner hook.
function OuterRoot () {
	return (
		<ErrorBoundary>
			<BrowserRouter>
				<storeContext.Provider value={store}>
					<InnerRoot />
				</storeContext.Provider>
			</BrowserRouter>
		</ErrorBoundary>
	)
}

function InnerRoot () {
	
	store.AppStore.navigate = useNavigate()
	store.AppStore.location = useLocation()

	return (
		<ThemeProvider theme={Theme}>
			<SnackbarProvider maxSnack={5}>
				<App />
				<store.AlertStore.RenderAlertModals />
			</SnackbarProvider>
		</ThemeProvider>
	)
}

const root = createRoot(document.getElementById('root'));
root.render(<OuterRoot />)