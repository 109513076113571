import * as Yup from 'yup'
import { useFormik } from "formik"
import FieldInfo from "./FieldInfo";
import { store } from '../stores/Store';


export class InformationRequest {

    platform = "nationality";
    formik = null;

    messageId: number;
    typeName: string;
    title: string;
    locatorPath: any;
    statement: string;
    mainText: string;
    suffix: string;
    cancelMessage: string;
    requestedFieldsObj: FieldInfo[];
    numberFields: number;
    errorText: string;
    
    // When false, take the first available certainty settings and apply to the whole information request
    globalCertainty: boolean = true;
    allowedCertainties: string[];
    certaintyMessages: string[];

    constructor(obj: Object) {

        console.log("InformationRequest", obj)

        // Cast json values to this
        Object.keys(obj).forEach((key) => {

            switch (key) {

                case "requestedFieldsObj":

                    var fields = []

                    obj[key].forEach((field: any) => {
                        fields.push(new FieldInfo(field))
                        
                        // Apply the certainty settings of any field to the whole information request
                        if (this.platform === "nationality" && field.certaintiesRequired) {
                            // this.globalCertainty = true
                            this.allowedCertainties = field.allowedCertainties
                            this.certaintyMessages = field.certaintyMessages
                        }
                    })

                    this.requestedFieldsObj = fields
                    break

                default:
                    this[key] = obj[key]
            }
        })
    }

    generateFormik = () => {
        this.formik = GenerateFormik(this)
        // this.setInitialValues()
        return this.formik
    }

    setAssumedValues = () => {
        this.requestedFieldsObj.forEach((field, i) => {
            var assumedValue = field.assumedValuesObj?.length ? field.assumedValuesObj : null
            if (assumedValue?.length) {
                this.formik.values[i.toString()] = field.style === "LISTBOX" ? assumedValue : assumedValue[0]
            }
        })
    }
}


function GenerateFormik (informationRequest) {

    var yupObj = {}
    var initialValues = {}

    informationRequest.requestedFieldsObj.forEach((field, f) => {

        // Set initial values
        switch (field.style) {
            case "LISTBOX":
                initialValues[f.toString()] = field.prepopulated && field.enteredValuesObj.length
                    ? field.enteredValuesObj
                    : []
                break
            case "DATE":
            case "TEXTBOX":
            case "RADIO":
            case "DROPDOWN":
            default:
                initialValues[f.toString()] = field.prepopulated && field.enteredValuesObj.length
                    ? field.enteredValuesObj[0]
                    : null
                break
        }

        // Set validation
        var fieldValidation: any;
        var requiredMessage = field.requiredMessage ? field.requiredMessage : "This field is required"
        var certaintyFieldKey = informationRequest.globalCertainty ? "certainty" : `${f}-certainty`

        switch (field.style) {
            case "DATE":
                fieldValidation = Yup.date()
                    .min(new Date(field.minDate), `Must be after ${field.minDate}`)
                    .max(new Date(field.maxDate), `Must be before ${field.maxDate}`)
                    .when(certaintyFieldKey, {
                        is: (certainty: string) => ["UNKNOWN", "TOFOLLOW"].includes(certainty),
                        then: () => Yup.date().nullable(),
                        otherwise: () => field.hasSecondaryCheckbox ? Yup.date().nullable() : Yup.date().typeError(requiredMessage)
                    })
                break

            case "LISTBOX":
                fieldValidation = Yup.array()
                    .min(field.minValueCount, `You must select at least ${field.minValueCount} value${field.minValueCount > 1 ? "s": ""}`)
                    .max(field.maxValueCount, `You are limited to ${field.maxValueCount} selection${field.maxValueCount > 1 ? "s": ""}`)
                    .when(certaintyFieldKey, {
                        is: (certainty: string) => ["UNKNOWN", "TOFOLLOW"].includes(certainty),
                        then: () => Yup.array().nullable(),
                        otherwise: () => Yup.array().typeError(requiredMessage)
                    })
                    
                    // Allow empty selections
                    field.minValueCount === 0
                        ? fieldValidation.nullable()
                        : fieldValidation.required(requiredMessage)
                break

            case "TEXTBOX":
                if (field.datatype.includes("Int32") || field.datatype.includes("Decimal")) {
                    fieldValidation = Yup.number().when(certaintyFieldKey, {
                        is: (certainty: string) => ["UNKNOWN", "TOFOLLOW"].includes(certainty),
                        then: () => Yup.number().nullable(),
                        otherwise: () => Yup.number().typeError("This field is required").required("This field is required")
                    })
                } else {
                    fieldValidation = !field.requiredMessage ? Yup.string().nullable() : Yup.string().when(certaintyFieldKey, {
                        is: (certainty: string) => ["UNKNOWN", "TOFOLLOW"].includes(certainty) || !field.maxChars,
                        then: () => Yup.string().nullable(),
                        otherwise: () => Yup.string().typeError(requiredMessage).test('length', `Must be under ${field.maxChars} characters`, val => !val || val.length < field.maxChars)
                    })
                }
                break
            case "RADIO":
            case "DROPDOWN":
            default:
                fieldValidation = Yup.string()
                    .when(certaintyFieldKey, {
                        is: (certainty: string) => ["UNKNOWN", "TOFOLLOW"].includes(certainty),
                        then: () => Yup.string().nullable(),
                        otherwise: () => Yup.string().typeError(requiredMessage)
                    })
                break
        }

        yupObj[f.toString()] = fieldValidation

        // Create certainty field
        if (field.certaintiesRequired) {
            initialValues[certaintyFieldKey] = field.enteredCertainties?.length 
                ? field.enteredCertainties[0] 
                : field.allowedCertainties[0]
        }
    })

    return useFormik({

        initialValues: initialValues,
        validationSchema: Yup.object(yupObj),

        onSubmit: async (values, actions) => {

            var valueArrays = []
            var certaintiesArrays = []

            Object.values(values).forEach((value, i) => { // Loop values

                // Make sure values are always in an array
                var valueArr = !Array.isArray(value) ? [value] : value

                // Convert empty strings to null
                valueArr = value === "" ? [""] : valueArr
    
                // Push to arrays to be sent
                if (Object.keys(values)[i].includes("-certainty")) { // Push field certainties
                    certaintiesArrays.push(valueArr)
                } else if (Object.keys(values)[i] === "certainty") { // Push master certainty
                    informationRequest.requestedFieldsObj.forEach(() => {
                        certaintiesArrays.push(valueArr)
                    })
                } else {
                    valueArrays.push(valueArr)
                }
            })

            await store.QuestionStore.submitAnswer(valueArrays, certaintiesArrays)
        }
    })
}