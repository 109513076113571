import React from "react"
import Ajax from "../../../Ajax"
import { Alert } from "../../.."
import { Questionnaire } from "../../../models/Interfaces"
import { Button, CircularProgress, Tab, Tabs } from "@mui/material"
import { CheckIcon } from "../../views/questionnaire_mgmt/QuestionnaireTable"
import { attributeKeys } from "../../../misc/AttributeKeys"


export default function QuestionnaireMergeFormSection ({
    enquiryUid,
    questionnaireUids,
    subjectPrefix,
    onCancel = () => {},
    onSubmit = (data) => {}
}) {

    const [loading, setLoading] = React.useState(true)
    const [enquiryData, setEnquiryData] = React.useState(null)
    const [questionnaires, setQuestionnaires] = React.useState<Questionnaire[]>(null)
    const [data, setData] = React.useState(null)

    const getQuestionnaireOriginIndex = (key) => {

        if (Object.keys(enquiryData).includes(key)) return "enquiry"

        for (var i = 0; i < questionnaires.length; i += 1) {
            if (Object.keys(questionnaires[i].answers).includes(key)) {
                return i
            }
        }

        return "derived"
    }

    var merge = async () => {

        setLoading(true)
        var qArray = []

        // Get enquiry data
        await Ajax.Automation.BuildAutomationData(enquiryUid, "{}", subjectPrefix).then((response) => {
            setEnquiryData(response.data)
        }).catch((response) => {
            Alert({message: response.data?.detail ? response.data.detail : "Couldn't load enquiry data"})
        })

        // Get questionnaires
        for (var i = 0; i < questionnaireUids.length; i += 1) {
            await Ajax.Questionnaire.Get(questionnaireUids[i]).then(response => {
                response.data.answers = JSON.parse(response.data.answers)
                response.data.json = JSON.parse(response.data.json)
                qArray.push(response.data)
            }).catch(() => {
                Alert({message: "Failed to get questionnaire"})
            })
        }

        // Combine data
        var data = {...enquiryData}
        for (var i = 0; i < qArray.length; i += 1) {
            data = {...qArray[i].answers, ...data}
        }

        // Insert record or used questionnaires
        data.questionnaires = qArray.map((qArrayItem) => { return {value: qArrayItem.uid, label: `${qArrayItem.displayName}, ${qArrayItem.template?.name}`} })

        // Backend processing
        await Ajax.Automation.BuildAutomationData(enquiryUid, JSON.stringify(data), subjectPrefix).then((response) => {
            setData(response.data)
            setQuestionnaires(qArray)
            console.log("Questionnaires in order of precedence", qArray)
        }).catch((response) => {
            Alert({message: response.data?.detail ? response.data.detail : "Couldn't load questionnaire"})
        })

        setLoading(false)
    }

    React.useEffect(() => {
        merge()
    }, [])

    if (!data || loading) return <CircularProgress style={{width: "20px", height: "20px", margin: "20px auto"}} />

    return (
        <div style={{display: "grid", gap: "5px"}}>
            <p>You have {questionnaires.length + 1} data sources, shown here in order of precedence.</p>

            <div style={{display: "flex", alignItems: "center", gap: "10px", paddingLeft: "5px"}}>
                <CheckIcon index="enquiry" />
                <span className="font-sm">Enquiry data</span>
            </div>

            {questionnaires.map((questionnaire, i) => (
                <div key={i} style={{display: "flex", alignItems: "center", gap: "10px", paddingLeft: "5px"}}>
                    <span className="font-sm"><CheckIcon index={i} /></span>
                    <span className="font-sm">{questionnaire.template.name} - {questionnaire.displayName}</span>
                </div>
            ))}

            <div style={{display: "flex", alignItems: "center", gap: "10px", padding: "5px 0 0 4px", borderTop: "1px solid lightgrey"}}>
                <CheckIcon />
                <span className="font-sm">Inferred data - based on data from the above sources</span>
            </div>

            <MergedValues data={data} getQuestionnaireOriginIndex={getQuestionnaireOriginIndex} />

            <div className="header">
                    <Button
                        className="btn btn-sm"
                        onClick={() => onCancel()}>
                        Back
                    </Button>

                    <Button
                        variant="contained"
                        className="btn btn-sm"
                        onClick={() => onSubmit(data)}>
                        Continue using this data
                    </Button>
                </div>
        </div>
    )
}

function MergedValues ({data, getQuestionnaireOriginIndex}) {

    const [tab, setTab] = React.useState<"subject"|"other_people"|"sponsor"|"advisor"|"misc">("subject")

    const formatKey = (key) => {
        if (Object.keys(attributeKeys).includes(key) && attributeKeys[key]) {
            return attributeKeys[key]
        }
        key = key.replace("subject", "applicant").replace("advisor", "adviser")
        key = key.replaceAll("_", " ")
        return key.charAt(0).toUpperCase() + key.slice(1);
    }

    var keysToDisplay = []
    var otherPeoplePrefixes = ["subject_supporter", "subject_partner", "subject_mother", "subject_father", "subject_first_parent", "subject_second_parent", "subject_first_guardian", "subject_second_guardian"]

    if (tab === "subject") {
        keysToDisplay = Object.keys(data).filter(key => !otherPeoplePrefixes.find(opp => key.startsWith(opp)) && key.startsWith("subject"))
    } else if (tab === "other_people") {
        keysToDisplay = Object.keys(data).filter(key => !!otherPeoplePrefixes.find(opp => key.startsWith(opp)))
    } else if (tab === "sponsor") {
        keysToDisplay = Object.keys(data).filter(key => key.startsWith("sponsor") || key.startsWith("job") || key.startsWith("cos"))
    } else if (tab === "misc") {
        keysToDisplay = Object.keys(data).filter(key => !key.startsWith("subject") && !key.startsWith("sponsor") && !key.startsWith("advisor") && !key.startsWith("job") && !key.startsWith("cos"))
    } else { // advisor and sponsor
        keysToDisplay = Object.keys(data).filter(key => key.startsWith(tab))
    }

    var style = {maxHeight: "350px", minHeight: "200px", overflow: "auto", padding: "0 5px", boxShadow: "0 -15px 15px -15px #00000040 inset, 0 15px 15px -15px #00000040 inset"}

    // Sort using KeyOrder
    const sortKeys = (keys) => {
        var newlist = []
        Object.keys(attributeKeys).filter(ko => keys.includes(ko)).forEach(ko => {
            newlist.push(ko)
        })
        // Any keys with no defined order will be lumped together alphabetically at the and.
        keys.filter(key => !newlist.includes(key)).sort((a,b) => a.localeCompare(b)).forEach(key => {
            newlist.push(key)
        })
        return newlist
    }
    

    return (
        <div>
            <div style={{width: "min-content", margin: "10px auto 0"}}>
                <Tabs
                    value={tab}
                    textColor="primary"
                    indicatorColor="primary">
                    <Tab onClick={() => setTab("subject")} value="subject" label="Applicant" />
                    <Tab onClick={() => setTab("other_people")} value="other_people" label="Related persons" />
                    <Tab onClick={() => setTab("sponsor")} value="sponsor" label="Job & Sponsor" />
                    <Tab onClick={() => setTab("advisor")} value="advisor" label="Adviser" />
                    <Tab onClick={() => setTab("misc")} value="misc" label="Miscellaneous" />
                </Tabs>
            </div>

            <div style={style}>
                {sortKeys(keysToDisplay).map((key, i) => {

                    var value = data[key]
                    var questionnaireOriginIndex = getQuestionnaireOriginIndex(key)

                    return (
                        <div key={i} style={{margin: "5px 0", display: "grid", gridTemplateColumns: "30px 300px auto", alignItems: "center", gap: "5px"}}>
                            <span><CheckIcon index={questionnaireOriginIndex} /></span>
                            <span>{formatKey(key)}</span>
                            {typeof value === 'string' ? (
                                <span>{value}</span>
                            ) : null}
                            {Array.isArray(value) ? (
                                <span>{value.length} {value.length === 1 ? "Entry" : "Entries"}</span>
                            ) : null}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}