import Ajax from '../Ajax'
import { store } from './Store'
import { Theme } from '../misc/Theme'
import { Alert, DisplayValue } from '..'
import { makeAutoObservable } from 'mobx'
import { Automator } from '../models/Automator'


interface RunningAutomation {
    automator: Automator;
    enquiryUid: string;
}


export class AutomationStore {

    runningAutomation: RunningAutomation = null;
    options: any[] = null;

    constructor () {
        makeAutoObservable(this)
    }

    get enquiryAutomations () {
        var automations = []

        if (store.EnquiryStore.enquiry?.automations?.length) {
            automations = store.EnquiryStore.enquiry?.automations
        }

        return automations
    }

    reset = () => {
        this.runningAutomation = null
    }

    run = async (automationId, additionalData = "{}") => {
        await Ajax.Automation.Run(store.EnquiryStore.enquiry.uid, automationId, additionalData).catch((response) => {
            Alert({message: response.data?.detail ? response.data?.detail : "Failed to run automation"})
        })
    }

    cancel = async () => {
        await Ajax.Automation.Cancel(this.runningAutomation.automator.id).catch((response) => {
            Alert({message: response.data?.detail ? response.data?.detail : "Failed to cancel automation"})
        })
    }

    getOptions = async () => {
        await Ajax.Automation.Options().then((response) => {
            this.options = response.data
        }).catch(() => {
            Alert({message: "Unable to get Automation options"})
        })
    }

    getQualifyingOnlineForms = (ruleId) => {

        if (!ruleId || !this.options?.length) { 
            return []
        }

        var reports = store.ReportStore.enquiryReportsCurrentOnly
        var ecReport = reports.find(r => r.reportType === "AEC")
        var flrReport = reports.find(r => r.reportType === "AFLR")
        
        var onlineFormsOptions = this.options.filter(option => {
            if (ruleId === "UK.D.33.1") {
                if (["SWV_EC", "SWV_COS_APPLY", "SWV_COS_ASSIGN_D"].includes(option.id) && flrReport?.valid) {
                    return false
                }
                if (["SWV_FLR", "SWV_COS_ASSIGN"].includes(option.id) && ecReport?.valid) {
                    return false
                }   
            }

            return option.qualifying_rules?.includes(ruleId)
        })

        return onlineFormsOptions
    }

    updateEnquiryAutomations = (enquiryUid, automator: Automator) => {
        if (store.EnquiryStore.enquiry?.uid === enquiryUid) {
            var existingItem = this.enquiryAutomations.find(a => a.automatorJson.includes(automator.id))

            if (existingItem) {
                existingItem.automatorJson = JSON.stringify(automator)
            } else {
                store.EnquiryStore.enquiry.automations.push({
                    "typeName": "Aora.Platform.Data.AutomationNode",
                    "parent": null,
                    "uid": automator.id.replace("AUTOMATOR", "AUTOMATION"),
                    "label": null,
                    "canEdit": true,
                    "canUpdate": true,
                    "canDelete": true,
                    automatorJson: JSON.stringify(automator)
                  })
            }
        }
    }

    handleAutomationNotification = (message) => {

        console.log("AutomationNotification", message)

        var { leafNode } = store.NodeStore
        var { enquiryUid, automator } = message

        this.runningAutomation = message

        var label = DisplayValue("automation_status", automator.status)
        var color = this.statusColor(automator.status, true)

        this.updateEnquiryAutomations(enquiryUid, automator)

        switch (automator.status) {
            case "RUNNING":
                return
            case "FAIL":
            case "FINISHED":
            case "CANCELLED":
            case "ERROR":
            case "WEBSITE_ERROR":
            case "MAPPING_ERROR":
            case "VALIDATION_ERROR":

                if (this.runningAutomation) {
                    this.runningAutomation = null
                }

                if (leafNode.uid !== enquiryUid) {
                    Alert({
                        message: `${label}`,
                        persist: true,
                        color: color,
                        actions: [{label: "Go to Automation", color: "info", action: () => store.NodeStore.navigateNode(enquiryUid)}]
                    })
                }

                return

            default:
                return
        }
    }

    statusColor = (status: string, mui: boolean = false) => {

        var color = "info"

        switch (status) {
            case "INSTANTIATED":
            case "RUNNING":
            case "CANCELLED":
                return mui ? "info" : Theme.palette.info.contrastText
            case "FINISHED":
                color = "success"
                break
            case "FAIL":
            case "ERROR":
            case "WEBSITE_ERROR":
            case "MAPPING_ERROR":
            case "VALIDATION_ERROR":
                color = "error"
                break
        }

        return mui ? color : Theme.palette[color].main
    }

    statusDescription = (automator: Automator) => {

        if (automator.status_message) {
            return automator.status_message
        }

        switch (automator.status) {
            case "INSTANTIATED":
                return "This automation is waiting to start because the server is at capacity."

            case "RUNNING":
                return "This automation is running."

            case "FINISHED":
                return "The Automation ran successfully."
    
            case "VALIDATION_ERROR":
                return "Some of your input data failed validation in the online form."
            
            case "WEBSITE_ERROR":
            case "HOME_OFFICE_ERROR":
                return "The target website of the automation is having some problems at the moment, please try again later."
    
            case "CANCELLED":
                return "This automation was cancelled."
    
            case "MAPPING_ERROR":
                return "There was an unexpected page which our software could not automate."
    
            case "ERROR":
                return "There was an unexpected error."
            
            default:
                return "Unkown status."
        }
    }
}