import { Alert, AlertModal } from "../../.."
import { Button, ButtonGroup } from "@mui/material"
import InlineMenu from "../../../misc/InlineMenu"


export default function Demo () {

    const AlertModalTest = (
        size?: "xs" | "sm" | "md" | "lg" | "xl", 
        body = `Nothing can quite compare to a comforting bowl of creamy pasta, especially one that's taken no more than 20 minutes to whip up. `, 
        code = false
    ) => {
        AlertModal({
            body: body, 
            title: `Size: ${size}`, 
            size: size, 
            code: code, 
            actions: [
                { label: "Okay", variant: "contained", action: () => Alert({message: "Okay then!"}) },
                { label: "Another", variant: "contained", action: () => AlertModalTest("sm") },
            ]
        })
    }

    return (
        <>
            <div className="settings-section">
                <h4>Alert Modals</h4>

                <ButtonGroup size="small" variant="text">
                    <Button onClick={() => {AlertModalTest()}}>auto</Button>
                    <Button onClick={() => {AlertModalTest("xs")}}>xs</Button>
                    <Button onClick={() => {AlertModalTest("sm")}}>sm</Button>
                    <Button onClick={() => {AlertModalTest("md")}}>md</Button>
                    <Button onClick={() => {AlertModalTest("lg")}}>lg</Button>
                    <Button onClick={() => {AlertModalTest("xl")}}>xl</Button>
                    -
                    <Button onClick={() => {
                        AlertModalTest(
                            "sm",
                            'if (persist) {\n\tactions = [...actions, { variant: "text", color: "info", label: <ClearIcon />}]\n}',
                            true
                    )}}>Code</Button>
                </ButtonGroup>
            </div>

            <div className="settings-section">
                <h4>Alerts</h4>

                <ButtonGroup size="small" variant="text">

                    <Button onClick={() => {
                        Alert({message: "Info", color: "info", persist: true, actions: [{ color: "error", variant: "text", label: "Reload App", action: () => window.location.reload() },]})
                    }}>Info
                    </Button>

                    <Button onClick={() => { Alert({message: "Success", color: "success"}) }}>
                        Succcess
                    </Button>

                    <Button onClick={() => {
                        Alert({message: "Default", color: "default"})
                    }}>Default
                    </Button>

                    <Button onClick={() => {
                        Alert({message: "Error", color: "error"})
                    }}>Error
                    </Button>

                </ButtonGroup>
            </div>

            <div className="settings-section">
                <h4>Menus</h4>
                <InlineMenu menuItems={[
                    { label: "Item 1", action: () => Alert({message: "Item 1"}) },
                    { label: "Item 2", action: () => Alert({message: "Item 2"}) },
                ]} />
            </div>
        </>
    )
}