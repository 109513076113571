import React from "react"
import QuestionnaireSelectField from "./QuestionnaireSelectField"


export default function QuestionnaireSelectFormSection ({
    formId,
    value,
    guidance = null,
    onSubmit = (questionnaireId) => {}
}) {

    const [questionnaireUid, setQuestionnaireUid] = React.useState(value)

    return (
        <form 
            id={formId} 
            className="form-fields" 
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit(questionnaireUid)
            }}>
            
            {guidance ? <p>{guidance}</p> : null}
            
            <QuestionnaireSelectField 
                value={questionnaireUid} 
                showRemoveButton={true}
                onChange={(uid) => setQuestionnaireUid(uid)} />
        </form>
    )
}