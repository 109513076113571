import React from "react"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Tab, Tabs } from "@mui/material"
import { FormSectionProps } from "../../../models/Interfaces"


export default function EnquirySettingsForm ({formId, onSubmit, section}: FormSectionProps) {

    const [tab, setTab] = React.useState(null)

    var initialSettingsValues = {}
    var settings = {}

    section.childrenObjects.forEach(setting => {
        // Build initial values for formik
        if (setting.datatype === "B") {
            initialSettingsValues[setting.code] = setting.value === "true"    
        } else {
            initialSettingsValues[setting.code] = setting.value !== null
                ? setting.value
                : setting.availableValues[0].value
        }
        // Add setting to settings object
        if (setting.category in settings) {
            settings[setting.category].push(setting)
        } else {
            settings[setting.category] = [setting]
        }
        // Set default tab to the category of the first setting
        if (!tab) {
            setTab(setting.category)
        }
    })

    const formik = useFormik({
        initialValues: initialSettingsValues,

        onSubmit: (values) => {
            var newSection = {...section}
            newSection.childrenObjects = section.childrenObjects?.map(setting => {
                if (setting.code in values) {
                    setting.value = values[setting.code].toString()
                }
                return setting
            })
            onSubmit(newSection)
        }
    })

    const SettingField = ({setting}) => {

        if (setting.datatype === "B") {
            return (
                <Field.Checkbox
                    formik
                    name={setting.code}
                    label={setting.label}
                    value={!!formik.values[setting.code]}
                    warning={false}
                    onChange={formik.handleChange} />
            )
        }
        if (setting.datatype === "I") {
            return (
                <Field.Select
                    formik
                    name={setting.code}
                    label={setting.label}
                    value={formik.values[setting.code]}
                    // warning={false}
                    options={setting.availableValues.map(option => {
                        return {
                            value: option.value,
                            label: option.label === null ? option.value : option.label
                        }
                    })}
                    onChange={(e) => formik.handleChange(e)} />
            )
        }
        if (setting.datatype === "S") {
            return (
                <Field.Select
                    formik
                    name={setting.code}
                    label={setting.label}
                    value={formik.values[setting.code]}
                    // warning={false}
                    options={setting.availableValues.map(option => {
                        return {
                            value: option.value,
                            label: option.label === null ? option.value : option.label
                        }
                    })}
                    onChange={(e) => formik.handleChange(e)} />
            )
        }
        return <h5>{setting.label}</h5>
    }

    var settingsToDisplay = tab in settings && Array.isArray(settings[tab]) ? settings[tab] : []

    return (
        <form id={formId} className="form-fields" onSubmit={formik.handleSubmit}>
            <p>We suggest that you leave the default settings</p>

            {Object.keys(settings).length > 1 ? (
                <Tabs
                    value={tab}
                    textColor="primary"
                    indicatorColor="primary">
                    {"BASIC" in settings ? (
                        <Tab onClick={() => setTab("BASIC")} value="BASIC" label="Basic" />
                    ) : null} 
                    {"REPORT" in settings ? (
                        <Tab onClick={() => setTab("REPORT")} value="REPORT" label="Report" />
                    ) : null} 
                    {"ADVANCED" in settings ? (
                        <Tab onClick={() => setTab("ADVANCED")} value="ADVANCED" label="Advanced" />
                    ) : null} 
                </Tabs>
            ) : null}

            <div style={{display: "grid", gap: "10px", marginTop: "15px"}}>
                {settingsToDisplay.map((setting, i) => <SettingField key={i} setting={setting} /> )}
            </div>
        </form>
    )
}