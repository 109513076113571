import FolderPanel from './FolderPanel';
import DetailPanel from "./detail/DetailPanel";
import QuestionPanel from "./QuestionPanel";


export default function MainView () {        
    return (
        <div className="view view-main">
            <FolderPanel />            
            <QuestionPanel />
            <DetailPanel />
        </div>
    )
}
