import { Button } from "@mui/material"
import { store } from "../../../stores/Store"
import { Icons } from "../../../misc/Icons"
import { observer } from "mobx-react-lite"


export default observer(function QuestionnaireFilterForm () {

    const { getQuestionnaires, searchString, setSearchString  } = store.QuestionnaireStore

    return (
        <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
            <div className="form-search">
                <input 
                    autoFocus 
                    value={searchString}
                    placeholder="Search"
                    spellCheck="false"
                    onChange={(e) => {
                        setSearchString(e.target.value)
                    }} />
            </div>
            <Button
                color="info"
                className="btn btn-sm"
                onClick={() => getQuestionnaires()}>
                <Icons.Refresh /> Refresh
            </Button>
        </div>
    )
})