import FormAutomationChart from "../../../../../misc/Charts"
import { Theme } from "../../../../../misc/Theme"


export default function AutomationReviewDetails ({automator}) {
    
    var genuineColor = Theme.palette.success.main
    var guessedColor = Theme.palette.warning.main
    var falseColor = Theme.palette.error.main

    return (
        <div className="automation-stats">
            <div style={{display: "grid", gridTemplateColumns: "10px 100px 20px", columnGap: "8px", alignItems: "center", marginLeft: "15px"}}>
                <div><div style={{backgroundColor: genuineColor, padding: "5px", borderRadius: "5px"}}></div></div>
                <div>Complete</div>
                <div>{automator.genuine_value_count}</div>
            
                <div><div style={{backgroundColor: guessedColor, padding: "5px", borderRadius: "10px"}}></div></div>
                <div>Needs checking</div>
                <div>{automator.guessed_value_count}</div>
            
                <div><div style={{backgroundColor: falseColor, padding: "5px", borderRadius: "10px"}}></div></div>
                <div>Needs updating</div>
                <div>{automator.false_value_count}</div>
            
                <div></div>
                <div>Total fields</div>
                <div>{automator.total_fields}</div>
            </div>

            <FormAutomationChart
                labels={["Genuine", "Empty", "Fake"]}
                chartData={[
                    automator.genuine_value_count,
                    automator.guessed_value_count,
                    automator.false_value_count
                ]}
                colors={[
                    genuineColor,
                    guessedColor,
                    falseColor,
                ]}
            />
        </div>
    )
}